import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState ,useEffect } from 'react'
import styles from './BeingInControl.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import SwiperBeingInControl from 'components/Global/Elements/SwiperBeingInControl/SwiperBeingInControl';
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle';
import ReactPlayer from 'react-player';
import VideoModal from 'components/Global/Elements/VideoModal/VideoModal';

type VideoType = {
  id:string,
  image:string,
  video:string,
  time:string,
  title:string,
  description:string,
}
type ComponentPropsType ={
  videos:any,
}
function BeingInControl(props:ComponentPropsType) {
  const {videos} =props
  const {t} =useTranslation()
  // const [categoriesList,setCategoriesList] = useState<string[]>([])
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [title ,setTitle] =useState<string>('')
  const [description ,setDescription] =useState<string>('')
  const [activeVideoSrc ,setActiveVideoSrc] =useState<string>('')
  const [activeImageSrc ,setActiveImageSrc] =useState<string>('')
  const [allVideos ,setAllVideos]=useState<VideoType[]>([])
  const [activeSwiperSection ,setActiveSwiperSection] =useState<number>(0)
  useEffect(()=>{
    // let videos:any = []
    // categories?.forEach((category:any)=>{
    //   videos.push(...category?.videos)
    // })
    console.log('videos',videos)
    setAllVideos(videos)
    setActiveVideoSrc(videos?.[0] as string)
    setActiveImageSrc(videos?.[0]?.image as string)
    setTitle(videos?.[0]?.title)
    setDescription(videos?.[0]?.description)
  },[videos])

  function changeActiveVideoSrc(video:string,title:string,description:string,image:string){
    setTitle(title)
    setDescription(description)
    setActiveVideoSrc(video)
    setActiveImageSrc(image)
  }
  return (
    <>
      <section className={styles['being-in-control']} id='portfolio'>

        {/* <SectionTitle title={`${t('Our Work')}`}/> */}
        <div className={styles['being-in-control__video-wrapper']}>
          <img src={activeImageSrc} className={styles['being-in-control__video']} alt='image'/>
          {/* <ReactPlayer 
            url={activeVideoSrc} 
            controls={false}
            playing={true}
            loop={true}
            muted ={true}
            width="100%"
            height="100%"
            className={styles['being-in-control__video']}
            config={{
                youtube: {
                    playerVars: { showinfo: 0 ,controls: 0, disablekb: 1, rel: 0}
                },
                facebook: {
                    appId: '12345'
                },
                vimeo:{
                  playerOptions:{
                    width:"100"
                  }
                }
            }}
          /> */}
        </div>
        <div className={styles['being-in-control__content-wrapper']}>
          <Container >
            {/* <ul className={styles['being-in-control__list']}>
              <li className={styles['being-in-control__list-item']}>
                <button 
                className={`${styles['being-in-control__list-button']} ${activeSwiperSection==0?styles['being-in-control__list-button--active']:''}`}
                onClick={()=>{setActiveSwiperSection(0)}}>{t('All')}</button>
              </li>
              {
                videos &&videos?.map((category:any,index:number)=>(
                  <li className={styles['being-in-control__list-item']} key={category?.id}>
                    <button 
                    className={`${styles['being-in-control__list-button']} ${activeSwiperSection==index+1?styles['being-in-control__list-button--active']:''}`}
                    onClick={()=>{setActiveSwiperSection(index+1)}}>{category?.title}</button>
                  </li>
                ))
              }
            </ul> */}
            <div >
              <h2 className={styles['being-in-control__content-title']}>{title}</h2>
              <p className={styles['being-in-control__content-description']}>{description}</p>
              <VideoModal video={activeVideoSrc}/>
              {/* <button className={styles['being-in-control__play-control']}>
                <RightArrow className={styles['being-in-control__play-control-icon']}/>
              </button> */}
            </div>

            {/* all */}
            <div className={`${styles['being-in-control__swiper-wrapper']} ${activeSwiperSection==0?styles['being-in-control__swiper-wrapper--active']:''}`}>
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                // ref={swiperRef}
                loop={true}
                // cssMode= {true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  allVideos &&allVideos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={video?.id}>
                      <SwiperBeingInControl 
                        image={video?.image} 
                        time={video?.time} 
                        video={video?.video} 
                        title={video?.title} 
                        description={video?.description} 
                        changeActiveVideoSrc={changeActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

            <div className={`${styles['being-in-control__swiper-wrapper']} `}>
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                // ref={swiperRef}
                loop={true}
                // cssMode= {true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  videos &&videos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={video?.id+index+video?.title}>
                      <SwiperBeingInControl 
                        image={video?.image} 
                        time={video?.time} 
                        video={video?.video} 
                        title={video?.title} 
                        description={video?.description} 
                        changeActiveVideoSrc={changeActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

          </Container>
        </div>
      </section>
    </>
  )
}

export default BeingInControl