import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './TranslationCompany.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import minstiryOfJusticePadge from 'assets/imgs/minstiryOfJusticePadge.png'
import IOSPadgeImage from 'assets/imgs/IOSPadgeImage.png'
import translationCompany100Img from 'assets/imgs/translationCompany100Img.png'
import translationCompany99Img from 'assets/imgs/translationCompany99Img.png'
import translationCompany98Img from 'assets/imgs/translationCompany98Img.png'
import { ServiceType} from 'views/Home/Home'
import {ReactComponent as OurServicesBrainIcon} from 'assets/icons/ourServicesBrainIcon.svg'
import {ReactComponent as OurServicesShieldIcon} from 'assets/icons/ourServicesShieldIcon.svg'
import {ReactComponent as OurServicesTrueIcon} from 'assets/icons/ourServicesTrueIcon.svg'

function TranslationCompany() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['translation-company']} id='translation-company'>
        <Container className='h-100'>
          <h2 className={styles['translation-company__title']}>{t('Translation Company Near Everyone')}</h2>

          <div className={styles['translation-company__features-wrapper']}>  
            {/* <div className={styles['translation-company__feature-wrapper']}>
              <img src={translationCompany100Img} className={styles['translation-company__image']} alt='section Padge'/>
              <h3 className={styles['translation-company__feature-title']}>{t('Efficient')}</h3>
            </div> */}
            <div className={styles['translation-company__feature-wrapper']}>
              <img src={translationCompany99Img} className={styles['translation-company__image']} alt='section Padge'/>
              <h3 className={styles['translation-company__feature-title']}>{t('Time Saver')}</h3>
            </div>
            <div className={styles['translation-company__feature-wrapper']}>
              <img src={translationCompany99Img} className={styles['translation-company__image']} alt='section Padge'/>
              <h3 className={styles['translation-company__feature-title']}>{t('Money Saver')}</h3>
            </div>
            <div className={styles['translation-company__feature-wrapper']}>
              <img src={translationCompany98Img} className={styles['translation-company__image']} alt='section Padge'/>
              <h3 className={styles['translation-company__feature-title']}>{t('Effort Saver')}</h3>
            </div>
          </div>

          <p className={styles['translation-company__description']}>{t("Have you ever wondered: How can I find a legal translation, interpretation or proofreading of my research or review of my book? We are close to you, and meet all your needs regarding translation and proofreading services. Our legal translation office provides all the required services, which we can deliver to you online. Our geographical coverage includes the entire Emirates and we offer various languages. Our translation services are considered among the best in the field of translation due to speed of delivery and high quality. We encourage you to visit our page and take advantage of the discounted prices we offer.")}</p>
          
        </Container>
      </section>
    </>
  )
}

export default TranslationCompany