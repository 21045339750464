import i18next from 'i18next';
import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './CustomDropdownMenu.module.css'
function CustomDropdownMenu({LangButton,children,active,setActive}) {
    return (
    <div className={`${styles["custom-dropdown"]} ${active?styles["active"]:''}`}>
        {/* <p className={linkClass}>{title}</p> */}
        {/* <div onClick={()=>setActive(false)} className={styles["custom-dropdown__item--background"]}></div> */}
        <LangButton/>
        <div className={styles["custom-dropdown-menu"]}>
            <ul className={styles["custom-dropdown__list"]}>
              {children}
            </ul>
        </div>
    </div>
  )
}
export default CustomDropdownMenu