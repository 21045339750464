import styles from './ContactUsForm.module.css'
import { useTranslation } from 'react-i18next'
import emiratesFlag from 'assets/imgs/emirateFlag.png'
import {ReactComponent as ContactFormUploadIcon} from 'assets/icons/contactFormUploadIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
import { emailPattern } from 'utils/features'
import { toast } from 'react-toastify'
import ReactLoading from "react-loading";
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
function ContactUsForm() {
    const {t} =useTranslation()
    const [name,setName] = useState<string>('')
    const [email,setEmail] = useState<string>('')
    const [message,setMessage] = useState<string>('')
    const [phone,setPhone] = useState<string>('')
    const [city,setCity] = useState<string>('')
    const [service,setService] = useState<string>('')
    const [terms,setTerms] = useState(false)
    const [subject,setSubject] = useState<string>('')
    const [isSubmitting ,setIsSubmitting] = useState(false)
    const [file,setFile]=useState<any>({})
    
    function validateInputs(){
        let status =true
        if(!terms){
            toast.error(t('You Must Agree To The Policies'))
            status=false
        }
        if(!name){
            toast.error(t('Contact Name Is Required'))
            status=false
        }
        if(!message){
            toast.error(t('Contact Message Is Required'))
            status=false
        }
        // if(!subject){
        //     toast.error(t('Contact Subject Is Required'))
        //     status=false
        // }
        if(!phone){
            toast.error(t('Contact phone Is Required'))
            status=false
        }
        if(!city){
            toast.error(t('Contact city Is Required'))
            status=false
        }
        if(!service){
            toast.error(t('Contact Service Is Required'))
            status=false
        }
        if((!emailPattern.test(email))){
            toast.error(t('Email Must Be Valid Email'))
            status=false
        }
        return status
    }
    
    function sendMessage(){
        if(!validateInputs()){
            return
        }
        setIsSubmitting(true)
        const formData  = new FormData()
        formData.append('name',name)
        formData.append('email',email)
        formData.append('message',message)
        formData.append('phone',phone)
        formData.append('city',city)
        formData.append('category',service)
        formData.append('file',file)
    
        axiosConfig.post(`/contact-us/create-contact-us`,formData).then(res=>{
            setIsSubmitting(false)
            setName('')
            setEmail('')
            setMessage('')
            // setSubject('')
            setService('')
            setCity('')
            setPhone('')
            toast.success(t(`Contact Us Added Successfully`))
        }).catch(err=>{
            setIsSubmitting(false)
            toast.error(t('Something went wrong'))
        })
    }
    function hanldeUploadFile(e:any){
        let files =e.target.files
    //   files = [...files ].map(file =>
    //       Object.assign(file, {
    //           preview: URL.createObjectURL(file),
    //       })
    //   )
        setFile(files[0])
    }
    
    const [categories,setCategories]= useState(localStorage.getItem('categories')?JSON.parse(localStorage.getItem('categories') as string) :[])
    const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
    function getAllCategories(){
        axiosConfig.get(`/category/all-categories`,{ 
            headers: {
                "Accept-Language": `${cookies?.i18next||'en'}`
            }
        }).then((res:any)=>{
            setCategories(res?.data?.data)
            localStorage.setItem('categories',JSON.stringify(res?.data?.data))
        }).catch(err=>{
            // setIsLoading(false)
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllCategories()
    },[])
    return (
        <div className={styles['contact-us']}>
            <div className={styles['contact-us--bg']}></div>
            <div className={styles['contact-us--content']}>
                <h2 className={styles['contact-us__form-title']}>{t('Request a Call Back or Free Quote')}</h2>
                <form className={styles['contact-us__form']}>
                    <input 
                        type='text'
                        placeholder={`${t('Your Name')}`}
                        className={styles['contact-us__form-input']}
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                    />
                    <input 
                        type='text'
                        placeholder={`${t('Your City')}`}
                        className={styles['contact-us__form-input']}
                        value={city}
                        onChange={(e)=>setCity(e.target.value)}
                    />
                    <div className={styles['contact-us__form-input-wrapper']}>
                        <input 
                            type='tel'
                            placeholder={`${t('Your Phone')}`}
                            className={styles['contact-us__form-input']}
                            value={phone}
                            onChange={(e)=>setPhone(e.target.value)}
                        />
                        {/* <img src={emiratesFlag} alt='flag icon' className={styles['contact-us__form-input-flag']}/> */}
                    </div>
                    <input 
                        type='Email'
                        placeholder={`${t('Email Address')}`}
                        className={styles['contact-us__form-input']}
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    <div className={styles['contact-us__form-services-wrapper']}>
                        <label className={styles['contact-us__form-services-label']}>{t('Please choose the service you need: *')}</label>
                        <div className={styles['contact-us__form-services-inputs-wrapper']}>
                            {
                                categories && categories?.map((category:any)=>(
                                    <div className={styles['contact-us__form-services-input-wrapper']} key={category?.id}>
                                        <input
                                            type='checkbox'
                                            className={styles['contact-us__form-services-input']}
                                            id={category?.id}
                                            name='services'
                                            value={category?.id}
                                            onChange={(e)=>setService(e.target.value)}
                                        />
                                        <label className={styles['contact-us__form-service-label']} htmlFor={category?.id}>{category?.title}</label>
                                    </div>
                                ))
                            }
                            {/* <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='translation'
                                    name='services'
                                    value='translation'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='translation'>{t('Translation')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='driving_license'
                                    name='services'
                                    value='driving_license'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='driving_license'>{t('Driving License')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='attestation'
                                    name='services'
                                    value='attestation'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='attestation'>{t('Attestation')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='notary_public'
                                    name='services'
                                    value='notary_public'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='notary_public'>{t('Notary Public')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='certified_true_copy'
                                    name='services'
                                    value='certified_true_copy'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='certified_true_copy'>{t('Certified True Copy')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='attestation'
                                    name='services'
                                    value='attestation'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='attestation'>{t('Attestation')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='data_flow'
                                    name='services'
                                    value='data_flow'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='data_flow'>{t('Data Flow')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='police_clearance'
                                    name='services'
                                    value='police_clearance'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='police_clearance'>{t('Police Clearance')}</label>
                            </div>
                            <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='proofreading'
                                    name='services'
                                    value='proofreading'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='proofreading'>{t('Proofreading')}</label>
                            </div> */}
                            {/* <div className={styles['contact-us__form-services-input-wrapper']}>
                                <input
                                    type='checkbox'
                                    className={styles['contact-us__form-services-input']}
                                    id='other'
                                    name='services'
                                    value='other'
                                    onChange={(e)=>setService(e.target.value)}
                                />
                                <label className={styles['contact-us__form-service-label']} htmlFor='other'>{t('Other')}</label>
                            </div> */}
                        </div>
                    </div>
                    <textarea
                        placeholder={`${t('Details')}`}
                        className={styles['contact-us__form-textarea']}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                    ></textarea>

                    <div className={styles['contact-us__form-upload-wrapper']}>
                        <input 
                        type='file' 
                        id='uploadFile' 
                        className={styles['contact-us__form-upload-input']}
                        onChange={(e:any) => hanldeUploadFile(e)}
                        />
                        <label htmlFor='uploadFile' className={styles['contact-us__form-upload-label']}>
                            <ContactFormUploadIcon className={styles['contact-us__form-upload-icon']}/>
                            {
                                file?.name ?
                                <p className={styles['contact-us__form-upload-text']}>{file?.name}</p>
                                :
                                <p className={styles['contact-us__form-upload-text']}>{t('Drag and Drop (or) Choose File')}</p>
                            }
                        </label>
                    </div>

                    <div className={styles['contact-us__form-services-input-wrapper']}>
                        <input
                            type='checkbox'
                            className={styles['contact-us__form-services-input']}
                            id='terms'
                            name='terms'
                            onChange={(e)=>setTerms(prevVal=>!prevVal)}
                        />
                        <label className={styles['contact-us__form-services-label']} htmlFor='terms'>
                            {t('Yes, I agree to')}
                            <Link to='/terms' className={styles['contact-us__form-services-label--terms']}>{t('the privacy policy and terms of service.')}</Link>
                        </label>
                    </div>
                    <button type='button' onClick={sendMessage} disabled={isSubmitting}
                        className={styles['contact-us__form-submit']}
                    >
                        {
                        !isSubmitting?t('Send')
                        :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ContactUsForm