import { Navbar ,Container} from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import alRasdLogo from 'assets/icons/alRasdLogo.svg'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'

import {ReactComponent as ContactUsCallUscon} from 'assets/icons/contactUsCallUscon.svg'
import {ReactComponent as ContactUsEmailIcon} from 'assets/icons/contactUsEmailIcon.svg'

import {ReactComponent as FooterTwitterIcon} from 'assets/icons/footerTwitterIcon.svg'
import {ReactComponent as FooterLinkedinIcon} from 'assets/icons/footerLinkedinIcon.svg'
import {ReactComponent as FooterInstagramIcon} from 'assets/icons/footerInstagramIcon.svg'
import {ReactComponent as FooterFacebookIcon} from 'assets/icons/footerFacebookIcon.svg'
import {ReactComponent as NavbarSearchIcon} from 'assets/icons/NavbarSearchIcon.svg'
import {ReactComponent as ContactUsWhatsappcon} from 'assets/icons/contactUsWhatsappcon.svg'
import { useSelector } from 'react-redux'
type ComponentPropsType ={
    isPageScrolling:boolean,
    isSideMenuShown:boolean,
    isRouteExist:boolean,
    setIsSideMenuShown:any,
}
function NavBar(props:ComponentPropsType) {
    const { isPageScrolling ,isSideMenuShown,isRouteExist,setIsSideMenuShown } =props
    const {t} =useTranslation()
    const selector = useSelector((state:any)=>state?.GlobalReducer)
    const [settings,setSettings]= useState<any>(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings') as string) :{})

    useEffect(()=>{
        setSettings(selector?.settings)
    },[selector])
    return (
        <Navbar expand="xl" className={`${styles['navbar']} ${isPageScrolling?styles['navbar--scrolling']:''}`}
        >
            {/* <div className={styles['navbar__top-nav']}>
                <Container>
                    <ul className={styles['navbar__nav-list']}>

                        <li className={styles['navbar__top-nav-menu-item']}>
                            <ContactUsCallUscon className={styles['navbar__top-nav-menu-item-icon']}/> {settings?.project_phone_number}
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <ContactUsWhatsappcon className={styles['navbar__top-nav-menu-item-icon']}/> {settings?.project_whats_app_number}
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            {t('Available Now')} 24/7
                        </li>
                        <li className={`${styles['navbar__top-nav-menu-item']} ${styles['navbar__top-nav-menu-item--margin']}`}>
                            <Link to={`${settings?.project_twitter_link}`} target='_blank'>
                                <FooterTwitterIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <Link to={`${settings?.project_instagram_link}`} target='_blank'>
                                <FooterInstagramIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <Link to={`${settings?.project_facebook_link}`} target='_blank'>
                                <FooterFacebookIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                    </ul>
                </Container>
            </div> */}
            <div className={styles['navbar__bottom-nav']}>
                <Container className={styles['navbar__nav__container']}>
                    <ul className={styles['navbar__nav-list']}>
                        <li className={styles['navbar__nav-logo-item']}>
                            <Link to='/' className={styles['navbar__nav-logo-link']}>
                                <img src={alRasdLogo} className={styles['navbar__nav-logo']}/>
                            </Link>
                            {/* <div className={styles['navbar__nav__lang-container']}>
                                <ChangeLangueList/>
                            </div> */}
                        </li>
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink end to='/' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Home')}</span>
                            </NavLink>
                        </li>
                        {/* <li className={styles['navbar__nav-menu-item']}>
                            <HashLink to='/#about-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('About')}</span>
                            </HashLink>
                        </li> */}
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink to='/about-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('About Us')}</span>
                            </NavLink>
                        </li>
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink to='/our-services' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Our Services')}</span>
                            </NavLink>
                        </li>
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink to='/blogs' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Blogs')}</span>
                            </NavLink>
                        </li>
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink to='/contact-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Contact')}</span>
                            </NavLink>
                        </li>
                        <li className={styles['navbar__nav-menu-item']}>
                            <NavLink to='/courses' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Classes')}</span>
                            </NavLink>
                        </li>
                        {/* <li className={styles['navbar__nav-menu-item']}>
                            <Link to='/'>
                                <NavbarSearchIcon className={styles['navbar__nav-menu-item-search-icon']}/>
                            </Link>
                        </li> */}
                        {/* <li className={styles['navbar__nav-menu-item']}>
                            <Link to='/contact-us' className={styles['navbar__nav-menu-item-link']}>For Urgent Request</Link>
                        </li> */}
                        <li className={styles['navbar__nav-menu-item']}>
                            <div className={styles['navbar__nav__lang-container']}>
                                <ChangeLangueList/>
                            </div>
                        </li>
                        {
                            selector?.token?
                            <li className={styles['navbar__nav-menu-item']}>
                                <NavLink to='/my-account' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                    <span className={styles['navbar__nav-link-text']}>{t('My Account')}</span>
                                </NavLink>
                            </li>
                        :
                            <li className={styles['navbar__nav-menu-item']}>
                                <NavLink to='/login' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                    <span className={styles['navbar__nav-link-text']}>{t('Login Now')}</span>
                                </NavLink>
                            </li>
                        }
                        {/* <li className={styles['navbar__nav-menu-item']}>
                            <HashLink to='/#contact-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                                <span className={styles['navbar__nav-link-text']}>{t('Contact Us')}</span>
                            </HashLink>
                        </li> */}
                        <li className={`${styles['navbar__nav-menu-item']} ${styles['navbar__nav-menu-item--active']}`}>
                            <button type='button' onClick={()=>setIsSideMenuShown((prevVal:boolean)=>!prevVal)}
                            className={`${styles['navbar__nav-menu']} ${isSideMenuShown?styles['navbar__nav-menu--active']:''}`}>
                                <span className={styles['navbar__nav-menu-span']}></span>
                                <span className={styles['navbar__nav-menu-span']}></span>
                                <span className={styles['navbar__nav-menu-span']}></span>
                            </button>
                        </li>
                    </ul>
                </Container>
            </div>
        </Navbar>
    )
}

export default NavBar