import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './ContactUs.module.css'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Header from 'components/Home/Header/Header'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import Footer from 'components/Global/Layout/Footer/Footer'
import contactUsImage from 'assets/imgs/contactUsImage.jpg'
import contactUsOnlineServices from 'assets/imgs/contactUsOnlineServices.png'
import contactUsSecurePayments from 'assets/imgs/contactUsSecurePayments.png'
import contactUsBestOffers from 'assets/imgs/contactUsBestOffers.png'
import contactUsBestQuality from 'assets/imgs/contactUsBestQuality.png'
import {ReactComponent as ContactUsClockIcon} from 'assets/icons/contactUsClockIcon.svg'
import {ReactComponent as ContactUsMesageeIcon} from 'assets/icons/contactUsMesageeIcon.svg'
import {ReactComponent as ContactUsPhoneeIcon} from 'assets/icons/contactUsPhoneeIcon.svg'
import {ReactComponent as ContactUsWhatsAppIcon} from 'assets/icons/contactUsWhatsAppIcon.svg'
import { emailConnect, emailPattern } from 'utils/features'
import ContactUsForm from 'components/Global/Elements/ContactUsForm/ContactUsForm'
import { Container } from 'react-bootstrap'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ReactLoading from "react-loading";

function ContactUsPage() {
  const {t} =useTranslation()
  const location = useLocation()
  const selector = useSelector((state:any)=>state?.GlobalReducer)
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  const [isPageLoading ,setIsPageLoading] = useState(false)
  const [settings,setSettings]= useState<any>(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings') as string) :{})

  useEffect(()=>{
    setSettings(selector?.settings)
  },[selector])

  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <header className={styles['header']}>
        <Container>
          {/* <img src={contactUsImage} alt='contact us image' className={styles['header__img']}/> */}
            <div className={styles['header__img']}>
              <div className={`loading__item`} id={`loading__item-contact-header`}>
                <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
              </div>
              <img 
                src={contactUsImage} 
                className={styles['header__img']} 
                alt='header Padge'
                onLoad={(e)=>(document.getElementById(`loading__item-contact-header`) as any).classList.add('d-none')}
                />
            </div>
          <div className={styles['header__info-wrapper']}>
            <h2 className={styles['header__info-title']}>{t('You speak.. We listen.')}</h2>
            <div className={styles['header__info-features-wrapper']}>
              <a href={emailConnect(settings?.project_email_address)}  className={styles['header__info-feature-wrapper']}>
                <ContactUsMesageeIcon className={styles['header__info-feature-wrapper-icon']}/>
                  <p className={styles['header__info-feature-wrapper-info-label']}>{settings?.project_email_address}</p>
              </a>
              <p className={styles['header__info-feature-wrapper']}>
                <ContactUsPhoneeIcon className={styles['header__info-feature-wrapper-icon']}/>
                  <p className={styles['header__info-feature-wrapper-info-label']}>{settings?.project_phone_number}</p>
              </p>
              <p className={styles['header__info-feature-wrapper']}>
                <ContactUsWhatsAppIcon className={styles['header__info-feature-wrapper-icon']}/>
                  <p className={styles['header__info-feature-wrapper-info-label']}>{settings?.project_whats_app_number}</p>
              </p>
              <p className={styles['header__info-feature-wrapper']}>
                <ContactUsClockIcon className={styles['header__info-feature-wrapper-icon']}/>
                  <p className={styles['header__info-feature-wrapper-info-label']}>{t('7 Days a Week - 8:00 am to 8:00 pm')}</p>
              </p>
            </div>

            <div className={styles['header__contact-form-wrapper']}>
              <ContactUsForm/>
            </div>
          </div>
        </Container>
      </header>
      {/* <section className={styles['contact-us__branches']}>
        <Container>
          <h2 className={styles['contact-us__branches-title']}>{t('Our Branches')}</h2>
        </Container>
      </section> */}
      <section className={styles['contact-us__features']}>
        <Container>
          <div className={styles['contact-us__features-wrapper']}>
            <div className={styles['contact-us__feature-wrapper']}>
              <img src={contactUsOnlineServices} alt='contact us feature img' className={styles['contact-us__features-img']}/>
              <h3 className={styles['contact-us__features-title']}>{t('Online Service')}</h3>
              <p className={styles['contact-us__features-description']}>{t('All your needs are satisfied online with one click through our store')}</p>
            </div>
            <div className={styles['contact-us__feature-wrapper']}>
              <img src={contactUsBestQuality} alt='contact us feature img' className={styles['contact-us__features-img']}/>
              <h3 className={styles['contact-us__features-title']}>{t('Best Quality')}</h3>
              <p className={styles['contact-us__features-description']}>{t('We deploy the best people to deliver you the best services at your doorstep')}</p>
            </div>
            <div className={styles['contact-us__feature-wrapper']}>
              <img src={contactUsBestOffers} alt='contact us feature img' className={styles['contact-us__features-img']}/>
              <h3 className={styles['contact-us__features-title']}>{t('Best Offers')}</h3>
              <p className={styles['contact-us__features-description']}>{t('Get the best prices for all services we have developed to make your life easier')}</p>
            </div>
            <div className={styles['contact-us__feature-wrapper']}>
              <img src={contactUsSecurePayments} alt='contact us feature img' className={styles['contact-us__features-img']}/>
              <h3 className={styles['contact-us__features-title']}>{t('Secure Payments')}</h3>
              <p className={styles['contact-us__features-description']}>{t('Pay at your ease using our various option of payment; bank transfer, online, card or cash')}</p>
            </div>
          </div>

        </Container>
      </section>
        <Footer />
    </motion.div>
    </>
  )
}

export default ContactUsPage