import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Footer.module.css'
import tulparLogo from 'assets/imgs/tulparLogo.png'
import payoutNavbarLogo from 'assets/imgs/payoutNavbarLogo.png'
import {ReactComponent as FooterYoutubeIcon} from 'assets/icons/footerYoutubeIcon.svg'
import {ReactComponent as FooterTwitterIcon} from 'assets/icons/footerTwitterIcon.svg'
import {ReactComponent as FooterTikTokIcon} from 'assets/icons/FooterTikTokIcon.svg'
import {ReactComponent as FooterInstagramIcon} from 'assets/icons/footerInstagramIcon.svg'
import {ReactComponent as FooterFacebookIcon} from 'assets/icons/footerFacebookIcon.svg'
import {ReactComponent as FooterSnapShat} from 'assets/icons/footerSnapShat.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { emailConnect, whtsAppConnect } from 'utils/features'
import './Footer.css'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'

import {ReactComponent as ContactUsCallUscon} from 'assets/icons/contactUsCallUscon.svg'
import {ReactComponent as ContactUsEmailIcon} from 'assets/icons/contactUsEmailIcon.svg'

import {ReactComponent as NavbarSearchIcon} from 'assets/icons/NavbarSearchIcon.svg'
import {ReactComponent as ContactUsWhatsappcon} from 'assets/icons/contactUsWhatsappcon.svg'
function Footer() {
    const {t} =useTranslation()
    const selector = useSelector((state:any)=>state?.GlobalReducer)
    const [settings,setSettings]= useState<any>(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings') as string) :{})

    useEffect(()=>{
        setSettings(selector?.settings)
    },[selector])
  return (
    <>
        {/* <Subscribe/> */}
        <footer data-aos-duration='800' data-aos-once='true'>
            <Container>
                <div className={styles['footer__section-links-list-wrapper']}>
                    <div className={styles['footer__section-links-lists-wrapper']}>
                        {/* <ul className={styles['footer__section-links-list']}>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/' className={styles['footer__section-link']}>
                                    {t('Terms & Conditions')}
                                </Link>
                            </li>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/' className={styles['footer__section-link']}>
                                    {t('Privacy Policy')}
                                </Link>
                            </li>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/' className={styles['footer__section-link']}>
                                    {t('FAQ')}
                                </Link>
                            </li>
                        </ul> */}
                        <ul className={styles['footer__section-links-list']}>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/how-we-work' className={styles['footer__section-link']}>
                                    {t('How We Work')}
                                </Link>
                            </li>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/who-we-are' className={styles['footer__section-link']}>
                                    {t('Who We Are')}
                                </Link>
                            </li>
                            <li className={styles['footer__section-links-item']}>
                                <Link to='/why-us' className={styles['footer__section-link']}>
                                    {t('Why Us')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ul className={styles['footer__section-social-links-list']}>
                        {/* <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_instagram_link}`} className={styles['footer__section-link']}>
                                <FooterYoutubeIcon/>
                            </Link>
                        </li> */}
                        {/* <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_twitter_link}`} className={styles['footer__section-link']}>
                                <FooterTwitterIcon/>
                            </Link>
                        </li> */}
                        {/* <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_instagram_link}`} className={styles['footer__section-link']}>
                                <FooterLinkedinIcon/>
                            </Link>
                        </li> */}
                        {/* <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_youtube_link}`} className={styles['footer__section-link']}>
                                <FooterYoutubeIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_instagram_link}`} className={styles['footer__section-link']}>
                                <FooterInstagramIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_tik_tok_link}`} className={styles['footer__section-link']}>
                                <FooterTikTokIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['footer__section-links-item']}>
                            <Link to={`${settings?.project_snap_shat_link}`} className={styles['footer__section-link']}>
                                <FooterSnapShat className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li> */}
                    </ul>
                </div>

            </Container>
            <div className={styles['footer__bottom-wrapper']}>
                <p className={styles['footer__copywrite-desc']}>{t('All rights are reserved for Al Rasd Legal Translation Centre')}</p>
            </div>
            <div className={styles['navbar__top-nav']}>
                <Container>
                    <ul className={styles['navbar__nav-list']}>

                        <li className={styles['navbar__top-nav-menu-item']}>
                            <ContactUsCallUscon className={styles['navbar__top-nav-menu-item-icon']}/> {settings?.project_phone_number}
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <ContactUsWhatsappcon className={styles['navbar__top-nav-menu-item-icon']}/> {settings?.project_whats_app_number}
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            {t('Available Now')} 24/7
                        </li>
                        {/* <li className={`${styles['navbar__top-nav-menu-item']} ${styles['navbar__top-nav-menu-item--margin']}`}>
                            <Link to={`${settings?.project_twitter_link}`} target='_blank'>
                                <FooterTwitterIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li> */}
                        <li className={`${styles['navbar__top-nav-menu-item']} ${styles['navbar__top-nav-menu-item--margin']}`}>
                            <Link to={`${settings?.project_youtube_link}`} target='_blank'>
                                <FooterYoutubeIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <Link to={`${settings?.project_instagram_link}`} target='_blank'>
                                <FooterInstagramIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <Link to={`${settings?.project_tik_tok_link}`} target='_blank'>
                                <FooterTikTokIcon className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                        <li className={styles['navbar__top-nav-menu-item']}>
                            <Link to={`${settings?.project_snap_shat_link}`} target='_blank'>
                                <FooterSnapShat className={styles['navbar__top-nav-menu-item-icon']}/>
                            </Link>
                        </li>
                    </ul>
                </Container>
            </div>
        </footer>
    </>
  )
}

export default Footer