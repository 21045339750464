import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './OnlineTranslation.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import onlineTranslationImage from 'assets/imgs/onlineTranslationImage.jpg'
import onlineTranslationImage2 from 'assets/imgs/onlineTranslationImage2.jpg'
import ReactLoading from "react-loading";

function OnlineTranslation() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['online-translation']} id='online-translation'>
        <Container className='h-100'>
          <Row className={styles['online-translation__item']}>
            <Col lg='5'>
              <div className='position-relative'>
                <div className={`loading__item`} id={`loading__item-online-translation1`}>
                  <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                </div>
                <img 
                  src={onlineTranslationImage} 
                  className={styles['online-translation__image']} 
                  alt='section Padge'
                  onLoad={(e)=>(document.getElementById(`loading__item-online-translation1`) as any).classList.add('d-none')}
                  />
              </div>
            </Col>
            <Col lg='7'>
              <div className={styles['online-translation__content-wrapper']}>
                <h3 className={styles['online-translation__title']}>{t('Online Translation Services')}</h3>
                <p className={styles['online-translation__description']}>{t('Digitalization and fast-paced developments have increased demand on online translation services. Hence, we have developed a full-fledged translation solution to attend to the translation needs of all our clients. Call us and get a free quote to translate your document.')}</p>
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col lg='7'>
              <div className={styles['online-translation__content-wrapper']}>
                <h3 className={styles['online-translation__sub-title']}>{t('Best Priced Translation Services')}</h3>
                <p className={styles['online-translation__description']}>{t('Digitalization and fast-paced developments have increased demand on online translation services. Hence, we have developed a full-fledged translation solution to attend to the translation needs of all our clients. Call us and get a free quote to translate your document.')}</p>
              </div>
            </Col>
            <Col lg='5'>
              <div className='position-relative'>
                <div className={`loading__item`} id={`loading__item-online-translation2`}>
                  <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                </div>
                <img 
                  src={onlineTranslationImage2} 
                  className={styles['online-translation__image']} 
                  alt='section Padge'
                  onLoad={(e)=>(document.getElementById(`loading__item-online-translation2`) as any).classList.add('d-none')}
                  />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default OnlineTranslation