import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './FreeSampleTranslation.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import ServicesNeededCard from 'components/Global/Elements/ServicesNeededCard/ServicesNeededCard'

import freeTranslationImg1 from 'assets/imgs/freeTranslationImg1.jpg'
import freeTranslationImg2 from 'assets/imgs/freeTranslationImg2.jpg'
function FreeSampleTranslation() {
  const {t} =useTranslation()
  return (
    <>
      <section className={styles['services-needed']} id='services-needed'>
        <Container className='h-100'>
            <Row>
              <Col lg='6'>
                <ServicesNeededCard
                  title={t('Free Sample Translation')}
                  description={t('We have you and your project covered! We are open to making a free sample translation of your document to make sure that you are satisfied even before you start dealing with us. Send us your document to benefit from these free sample translations.')}
                  image={freeTranslationImg1}
                />
              </Col>
              <Col lg='6'>
                <ServicesNeededCard
                  title={t('Fast & Affordable Translation')}
                  description={t('We all need fast and affordable translation services with top quality. We ensure that you get the best quality translation as fast and affordable as no one else can offer. Call us or visit our online services page to get your translation ready in few hours.Benefit Now')}
                  image={freeTranslationImg2}
                />
              </Col>
            </Row>
        </Container>
      </section>
    </>
  )
}

export default FreeSampleTranslation