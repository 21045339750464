import styles from './ServicesNeededCard.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import ReactLoading from "react-loading";
type ComponentCustomPropsTypes={
    time:string,
    image:string,
    title:string,
    description:string,
    video:string,
    changeActiveVideoSrc:Function,
}
function ServicesNeededCard(props:any) {
    const {image,description,title,id}= props
    const {t} =useTranslation()
    const imgRef= useRef()
    return (
        <div className={styles['services-needed__card']}>
            {/* <div className='position-relative'> */}
                <div className={`loading__item`} ref={imgRef as any}>
                    <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                </div>
                <img 
                    src={image} 
                    className={styles['services-needed__card-image']} 
                    alt='section Padge'
                    onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                />
            {/* </div> */}
            <h3 className={styles['services-needed__card-title']}>{title}</h3>
            <p className={styles['services-needed__card-description']}>{description}</p>
            <Link to={`/product-details/${id}`} className={styles['services-needed__card-link']}>{t('Learn More')}</Link>
        </div>
    )
}

export default ServicesNeededCard