import  { useEffect } from 'react'
import styles from './SideBar.module.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './SideBar.css'
// import { HashLink } from 'react-router-hash-link'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
type ComponentPropsType ={
    isSideMenuShown:boolean,
    setIsSideMenuShown:any,
}
function SideBar(props:ComponentPropsType) {
    const { isSideMenuShown,setIsSideMenuShown } =props

    const {t} =useTranslation()
    const location = useLocation()
    const selector = useSelector((state:any)=>state?.GlobalReducer)
    
    useEffect(()=>{
        setIsSideMenuShown(false)
    },[location])
    return (
    <nav className={`${styles["sidebar"]} ${isSideMenuShown && styles['sidebar--open']}`}>
        <div className={styles["sidebar__content"]}>
            {/* <div className='d-flex justify-content-between mb-3'>
                <button role='button' onClick={()=>{setIsSideMenuShown(false)}} className={styles['sidebar__close-button']}>X</button>
            </div> */}
            <ul className={styles["sidebar__nav-list"]}>
                {/* <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <NavLink to='/' className={styles["sidebar__nav-link"]}>                    
                        <img src={logoResponsive} alt='logo' className={`${styles['sidebar__logo']}`}/>
                    </NavLink>
                </li> */}
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <ChangeLangueList/>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('Home')}</span>
                    </HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/about-us' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('About Us')}</span>
                    </HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/our-services' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('Our Services')}</span>
                    </HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/contact-us' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('Contact Us')}</span>
                    </HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/blogs' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('Blogs')}</span>
                    </HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/courses' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                        <span className={styles['sidebar__nav-link-text']}>{t('Classes')}</span>
                    </HashLink>
                </li>
                {
                    selector?.token?
                    <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                        <HashLink to='/my-account' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                            <span className={styles['sidebar__nav-link-text']}>{t('My Account')}</span>
                        </HashLink>
                    </li>
                :
                    <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                        <HashLink to='/login' className={`${styles['sidebar__nav-link']} sidebar__nav-link`}>
                            <span className={styles['sidebar__nav-link-text']}>{t('Login Now')}</span>
                        </HashLink>
                    </li>
                }
            </ul>
        </div>
        <div className={styles["sidebar__overlayer"]} onClick={()=>{setIsSideMenuShown(false)}}></div>
    </nav>
  )
}

export default SideBar