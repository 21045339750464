import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './WhyUs.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import whyUsImg from 'assets/imgs/whyUsImg.jpg'
import whoWeAreWhatWeOfferImg from 'assets/imgs/whoWeAreWhatWeOfferImg.jpg'
import WhoWeAreTranslationFirm from 'assets/imgs/WhoWeAreTranslationFirm.png'
import Footer from 'components/Global/Layout/Footer/Footer'
function WhyUs() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
 
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <header className={styles['why-us__header']}>
        <h1 className={styles['why-us__header--title']}>{t('Why Us')}</h1>
      </header>
      <Container>
        <p className={styles['why-us__first-description']}>
          {t('I will always find it ideal to have a dedicated translation company near me for all translation services needed, which can be achieved through presence online. Visit our page and enjoy the discounted prices we offer. It is a matter of fact that we are living in this digital era that is characterized by the large availability of almost all services online, but still some physical contact is very frequently needed. Therefore, at Elite Legal Translation Services we are always developing our translation services and solutions to suit all tastes and satisfy all needs. With us you don’t have to worry as we can reach you online and any place on ground. Our branches are spread in many countries and cities to be very close to you to make sure that your search for a translation company or office near me is never as easy and helpful as it never was; all you need to do is look for us by searching for translation services near me and you will find us within your sight. Contact us now to benefit from our services and offers.')}
        </p>
        <p className={styles['why-us__first-description']}>
          {t('Online professional translation company near everyone to make your life easier by providing you with online easily accessible services in the field of translation, driving license, attestation, copywriting, interpretation and notary public applications.')}
        </p>
      </Container>
      <section className={styles['why-us']}>
        <Container>
          <Row>
            <Col lg='6'>
              <div>
                <h2 className={styles['why-us__title']}>{t('Licensed Legal Translation Company')}</h2>
                <p className={styles['why-us__description']}>
                  {t('I will always find it ideal to have a licensed legal translation company near me for all translation services needed, which can be achieved through presence online. Visit our page and enjoy the discounted prices we offer. It is a matter of fact that we are living in this digital era that is characterized by the large availability of almost all services online, but still some physical contact is very frequently needed. Therefore, at Elite Legal Translation Services we are always developing our translation services and solutions to suit all tastes and satisfy all needs. With us you don’t have to worry as we can reach you online and any place on ground. Our branches are spread in many countries and cities to be very close to you to make sure that your search for a translation company or office near me is never as easy and helpful as it never was; all you need to do is look for us by searching for translation services near me and you will find us within your sight. Contact us now to benefit from our services and offers.')}
                </p>
              </div>
            </Col>
            <Col lg='6'>
              <img src={whyUsImg} alt='whoWeAreWhatWeOfferImg' className={styles['why-us__img']}/>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <iframe width="560" height="315" className={styles['why-us__video']} src="https://www.youtube.com/embed/8WHiTiKs7ko?si=nXmUJcfGncB6ta7G" title="YouTube video player"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
      </Container>

      <section className={styles['why-us__features']}>
        <Container>
          <div className={styles['why-us__features-wrapper']}>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Attested Translation Services')}</h2>
              <p className={styles['why-us__feature-description']}>{t('Every document to be used in UAE must use attested translation services by a sworn translator. Translated documents need to be attested by the Ministry of Justice after translating them by the sworn translator. We will translate, certify the translation and go to the Ministry of Justice to have it attested, so you can save you time and energy.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Good Price Translation Services')}</h2>
              <p className={styles['why-us__feature-description']}>{t('We assure you to receive good price translation services at high quality levels. Due to using our highly effective and efficient project management system, which allows us to stay connected with all our project managers, translators, interpreters, proofreaders and editors and tremendously improves our workflow, we have some of the lowest rates in the market.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Lawsuit Legal Translation')}</h2>
              <p className={styles['why-us__feature-description']}>{t('Our team of specialized sworn translators is assembled to help you to get you lawsuit legal translation very fast. This includes court papers, claim documents, civil procedure, court document, statement of claim and any other supportive documents you need them translated to be submitted to the court.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
          </div>
        </Container>
      </section>


      <div className={styles['why-us__quality-wrapper']}>
        <Container>
          <h2 className={styles['why-us__quality-title']}>{t('Top Quality Translation')}</h2>
          <p className={styles['why-us__quality-description']}>
            {t('When it comes to language services, everyone expects to receive top quality translation to avoid any issues when using it. Since the UAE laws and regulations require all dealings and communications with the federal and local authorities to be in Arabic while most companies internally use the English language as the official language for communication and business, companies and businesses find themselves at all times in need to translate from English to Arabic most, or all, of their documents and papers to be submitted to the government entity. Our professional translator team ensures that you get the best English to Arabic translations for your documentation towards guaranteeing the precise delivery of your message to the government.')}
          </p>
        </Container>
      </div>

      <section className={styles['why-us__second-features']}>
        <Container>
          <h2 className={styles['why-us__second-title']}>{t('Affordable Creative Copywriting')}</h2>
          <p className={styles['why-us__second-description']}>{t('We have deployed a team of creative copywriters and content writers to assist you in delivering your messages to your target audience creatively utilizing our enhanced content writing skills and long experience in this field.')}</p>
          
          <div className={styles['why-us__features-wrapper']}>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Multilingual Website Translation')}</h2>
              <p className={styles['why-us__feature-description']}>{t('Multilingual website translation and localization should be done the right way; otherwise this has no benefits and might give reverse effects. Our team of copywriters, proofreaders and editors along with our web specialists are ready to take your website and your business to the next level trough efficient localization utilizing our long experience in the field of website localization.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Accurate Translation Services')}</h2>
              <p className={styles['why-us__feature-description']}>{t('We provide highly accurate translation services from and into over 110 languages and can do all language pairs. We cover all major types of translation and language related services. Our specialized and dedicated translator teams has the potential and capacity to provide you with contract translation, agreement translation, memorandum of association translation, power of attorney translation and any other translation need quickly and affordably as no other translation agency can.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Machine Translation Software')}</h2>
              <p className={styles['why-us__feature-description']}>{t('You may find machine translation software useful for your big project or if you are running on a small budget. We may be able to use such machine translation software to translate your documents. Such software may assist us in spotting the repeated parts and do them automatically. Then, we have our professionals revise and refine the translation to reduce costs.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
          </div>
        </Container>
      </section>
    
      <Container>
        <div className={styles['why-us__feature-wrapper']}>
          <h2 className={styles['why-us__second-title']}>{t('Affordable Certified Translation')}</h2>
          <p className={styles['why-us__second-description']}>{t('We encourage you to benefit from our affordable certification translation services if you are thinking to immigrate to Canada, Australia, New Zealand, US, or any other country. You’ll need certified translation of all your personal documents (birth certificate, marriage contract, study certificates, university degree) to use them to support your application. Our services are affordable and assure you that to receive your translated documents in under 24 hours.')}</p>
          <button className={styles['why-us__features-link']}>{t('Read More')}</button>
        </div>
      </Container>

      <section className={styles['why-us__second-features']}>
        <Container>
          <h2 className={styles['why-us__third-title']}>{t('Affordable Online Language Translation')}</h2>
          <div className={styles['why-us__features-wrapper']}>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Precise Spanish Translation')}</h2>
              <p className={styles['why-us__feature-description']}>{t('We have a highly professional Spanish translation team with an excellent experience; they are able to provide translations for any documents or websites from and into Spanish, including legal translation.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Professional Chinese Translation')}</h2>
              <p className={styles['why-us__feature-description']}>{t('Our professional Chinese translation services assist you to keep pace with the global spread China has achieved on the economic and cultural levels. This has entailed the demand on its language as it is on its goods and services. The Mandarin and Malayalam languages are the common ones used in China or people who want to communicate with Chinese persons. Elite Legal Translation Services has expanded its language services to include translating from and to the Chinese languages. Send us your document or contact us and we will assist you fast like the Chinese do.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
            <div className={styles['why-us__feature-wrapper']}>
              <h2 className={styles['why-us__feature-title']}>{t('Excellent German Translation')}</h2>
              <p className={styles['why-us__feature-description']}>{t('Need your documents issued in German to be legally translated? Our excellent German translation services are designed to make your life easier. We have developed our German translation team due to the fact that German is the official language in many countries, including Germany, Liechtenstein, Luxembourg, Austria, and four cantons of Switzerland. It is one of the most prominent languages in the business field, especially in the Euro market and is the most widely spoken native language in the European Union. Our network of highly qualified translators and native language professionals, we are able to satisfy all your language and translation needs from and into German.')}</p>
              <button className={styles['why-us__features-link']}>{t('Read More')}</button>
            </div>
          </div>
        </Container>
      </section>
      
      <Container className='mb-5'>
        <div className={styles['why-us__feature-wrapper']}>
          <h2 className={styles['why-us__second-title']}>{t('Online Translation Service')}</h2>
          <p className={styles['why-us__second-description']}>{t('We know that it is increasingly becoming need to opt for online translation services. It is true and more efficient to do things online to avoid the hassle of driving and looking for a parking in such busy cities. If you are far from any of our offices or don’t have the time to visit us, you can contact us by WhatsApp, email or use our online translation services from the comfort of your office or home. We provide multiple ways to get in touch with our team and get a free quote for your document: by phone, e-mail, contact forms or WhatsApp, you are able to quickly get in contact with our project managers no matter where in the world you may be. Any questions you may have will be answered within few minutes and a quote sent to you by WhatsApp or e-mail, all at your convenience. If you are ready to proceed with your translation order, you can easily confirm and pay the quotation amount through any payment channel. As soon as the payment is received, we will assign the best translator and proofreader to translate your document and when they’re done and the translation is checked by our quality assurance team it will be sent to you')}</p>
          <button className={styles['why-us__features-link']}>{t('Read More')}</button>
        </div>
      </Container>
      
      <Container className='mb-5'>
        <div className={styles['why-us__feature-wrapper']}>
          <h2 className={styles['why-us__third-title']}>{t('Best Prices Translation')}</h2>
          <p className={styles['why-us__quality-description']}>{t('Are you seeking the best prices for your document translation? You have our attention caught! Based on our long experience extending for more than 20 years in the translation industry you have come to the right place to assist you in finding your language translation solution. We have offices in the USA, UAE, Lebanon and Syria to offer a 24-hour service without any extra cost or hidden charges. It is our values is to be transparent with our clients.We charge clear rates per word or per page for all language pairs. In addition to regular legal and other document translations that can be delivered within the same day, we offer certified, legal translations. We have succeeded over the past years to develop a brilliant full efficient project management system that is subject to monthly revisions to offer the most effective and efficient service that suit all needs of all clients of all sizes.We offer the most competitive prices per page or per word. Not only this but, we are also the most efficient and lowest priced translation firm for certified and notarized translations.')}</p>
        </div>
      </Container>

      <Container className='mb-5'>
        <div className={styles['why-us__feature-wrapper']}>
          <h2 className={styles['why-us__second-title']}>{t('Best-in-class Support')}</h2>
          <p className={styles['why-us__second-description']}>{t('With our offices in the USA, UAE, Lebanon and Syria, we are available 24/7 to translate for you. We are always very keen to deliver our clients with the best general and legal translation services around the clock with our project managers are always accessible to help you with any agreements, contracts, manuals, certificates or other documents you need translated. Due to using our in-house developed translation management system, we are connected with our team of translators, interpreters, proofreaders and editors online and we are able to take on any translation, interpretation, proofreading and editing task, from and to any language combination, no matter the size of your project or document. With Elite Legal Translation Services you no longer have to wait. As soon as you approve our quote, we will schedule and make all required arrangements to create contact between you and the right translators, proofreaders and editors and we start working on your project in minutes!')}</p>
          <button className={styles['why-us__features-link']}>{t('Read More')}</button>
        </div>
      </Container>


        <Footer/>

    </motion.div>
    </>
  )
}

export default WhyUs