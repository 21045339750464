import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Register.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import { Link, useNavigate } from 'react-router-dom'
import CoursesCard from 'components/Global/Elements/Cards/CoursesCard/CoursesCard'
import {ReactComponent as ServicesPaginationRightArrow} from 'assets/icons/servicesPaginationRightArrow.svg'
import {ReactComponent as ServicesPaginationLeftArrow} from 'assets/icons/servicesPaginationLeftArrow.svg'
import {ReactComponent as InputSearchIcon} from 'assets/icons/inputSearchIcon.svg'
import {ReactComponent as ServicesHeaderHeartIcon} from 'assets/icons/servicesHeaderHeartIcon.svg'
import {ReactComponent as ServicesHeaderCartIcon} from 'assets/icons/servicesHeaderCartIcon.svg'
import { emailPattern } from 'utils/features'
import { changeTokenAction } from 'reduxStore/Global/GlobalActions'
import { useDispatch } from 'react-redux'
import ReactLoading from "react-loading";
function Register() {
  const {t} =useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loginEmail ,setLoginEmail] = useState('')
  const [name ,setName] = useState('')
  const [phone ,setPhone] = useState('')
  const [loginPassword ,setLoginPassword] = useState('')
  const [registerEmail ,setRegisterEmail] = useState('')
  const [errorMessage ,setErrorMessage] = useState('')
  const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
  const [isloading,setIsloading] = useState(false)
  const [isMessageError,setIsMessageError] = useState(false)
  const [isRegisterLoading,setIsRegisterLoading] = useState(false)
  const [isLoginLoading,setIsLoginLoading] = useState(false)
  const [isPasswordVisible , setIsPasswordVisible] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['i18next','token']);

  function registerAccount(){
    if(!emailPattern.test(loginEmail)){
      setErrorMessage(`${t('Please provide a valid Register email address.')}`)
      setIsMessageError(true)
      return 
    }
    setIsRegisterLoading(true)
    
    axiosConfig.post('/user/create-user',{email : loginEmail,password:loginPassword,name:name,phone_number:phone}).then(res=>{
        setIsRegisterLoading(false)
        setIsMessageError(false)
        setLoginEmail('')
        setName('')
        setPhone('')
        setCookie('token',res?.data?.data?.token)
        navigate('/my-account')
        dispatch(changeTokenAction(res?.data?.data?.token))
    }).catch(err=>{
        setIsRegisterLoading(false)
        setErrorMessage(err?.response?.data?.message)
      // setIsSuccess(false)
        setIsMessageError(true)
    })
  }
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['register']}>
          <Container>
            <div className={styles['register-content-wrapper']}>
              <h1 className={styles['register__title']}>{t('Register to Elrasd')}</h1>
              <form className={styles['register__form-wrapper']}>
                <div className={styles['register__input-wrapper']}>
                  <label className={styles['register__input-label']}>{t('Username')}</label>
                  <input
                    type='text'
                    className={styles['register__input']}
                    placeholder={`${t('Enter Username')}`}
                    value={name}
                    onChange={e=>setName(e.target.value)}
                  />
                </div>
                <div className={styles['register__input-wrapper']}>
                  <label className={styles['register__input-label']}>{t('Phone')}</label>
                  <input
                    type='text'
                    className={styles['register__input']}
                    placeholder={`${t('Enter Phone')}`}
                    value={phone}
                    onChange={e=>setPhone(e.target.value)}
                  />
                </div>
                <div className={styles['register__input-wrapper']}>
                  <label className={styles['register__input-label']}>{t('Email')}</label>
                  <input
                    type='email'
                    className={styles['register__input']}
                    placeholder={`${t('Enter Email')}`}
                    value={loginEmail}
                    onChange={e=>setLoginEmail(e.target.value)}
                  />
                </div>
                <div className={styles['register__input-wrapper']}>
                  <label className={styles['register__input-label']}>{t('Password')}</label>
                  <input
                    type='password'
                    className={styles['register__input']}
                    placeholder={`${t('Enter Password')}`}
                    value={loginPassword}
                    onChange={e=>setLoginPassword(e.target.value)}
                  />
                </div>
                <p className={styles['register__login-text']}>
                  {t('Already have account?')}
                  <Link to='/login' className={styles['register__login-link']}>{t('Login Now')}</Link>
                </p>
                <button className={styles['register__submit']} type='button' onClick={registerAccount} disabled={isRegisterLoading||isLoginLoading}>
                  {
                    isRegisterLoading ?
                    <ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                    :
                    t('Register')
                  }
                </button>
                
              </form>
            </div>
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Register