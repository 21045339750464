import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './WhoWeAre.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import whoWeAreWhatWeOfferImg from 'assets/imgs/whoWeAreWhatWeOfferImg.jpg'
import WhoWeAreTranslationFirm from 'assets/imgs/WhoWeAreTranslationFirm.png'
import Footer from 'components/Global/Layout/Footer/Footer'
function WhoWeAre() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
 
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <header className={styles['who-we-are__header']}>
        <h1 className={styles['who-we-are__header--title']}>{t('Who We Are')}</h1>
      </header>

      <section className={styles['who-we-are__features']}>
        <Container>
          <div className={styles['who-we-are__features-wrapper']}>
            <div className={styles['who-we-are__feature-wrapper']}>
              <h2 className={styles['who-we-are__feature-title']}>{t('Vision')}</h2>
              <p className={styles['who-we-are__feature-description']}>{t('Be as close and responsive to customers’ needs as possible to facilitate their work.')}</p>
            </div>
            <div className={styles['who-we-are__feature-wrapper']}>
              <h2 className={styles['who-we-are__feature-title']}>{t('Mission')}</h2>
              <p className={styles['who-we-are__feature-description']}>{t('Delivering fast services with high quality and competitively peerless priceless.')}</p>
            </div>
            <div className={styles['who-we-are__feature-wrapper']}>
              <h2 className={styles['who-we-are__feature-title']}>{t('Values')}</h2>
              <p className={styles['who-we-are__feature-description']}>{t('Be as close and responsive to customers’ needs as possible to facilitate their work.')}</p>
            </div>
          </div>
        </Container>
      </section>

      <section className={styles['what-we-offer']}>
        <Container>
          <Row>
            <Col>
              <div>
                <h2 className={styles['what-we-offer__title']}>{t('What We Offer')}</h2>
                <p className={styles['what-we-offer__description']}>
                  {t('Our services range and continuously expand to respond to varying needs and demands of our valued customers. It all started with providing legal translation services, but has been diversifying to providing the following services:')}
                </p>
                <ul className={styles['what-we-offer__features-list']}>
                  <li className={styles['what-we-offer__features-item']}>{t('Translation solutions')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Driving license services')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Attestation services')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Notary public applications')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Copywriting solutions')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Interpreting services')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Police clearance certificate')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('Emirates ID')}</li>
                  <li className={styles['what-we-offer__features-item']}>{t('More on the way…')}</li>
                </ul>
              </div>
            </Col>
            <Col>
              <img src={whoWeAreWhatWeOfferImg} alt='whoWeAreWhatWeOfferImg' className={styles['what-we-offer__img']}/>
            </Col>
          </Row>
          <div className={styles['what-we-offer__quality-wrapper']}>
            <h2 className={styles['what-we-offer__quality-title']}>{t('Quality is Priority')}</h2>
            <p className={styles['what-we-offer__quality-description']}>
              {t('Quality is priority in everything we do and quality control is always at the core of our daily operation of the company. In the actual business operation of the company, prior to the translation services, the company has cautiously analyzed the customer demand and then agreed to a common understanding with them on quality, translated manuscript delivery date and the price, and thus the customer satisfactory index has been greatly improved.')}
            </p>
          </div>
        </Container>
      </section>

      <section className={styles['our-translation-firm']}>
        <Container>
          <div className={styles['our-translation-firm__wrapper']}>
            <p className={styles['our-translation-firm__title']}>
              <span className={styles['our-translation-firm__title--span']}>{t('Our translation firm ')}</span>
              {t('is a member in the Abu Dhabi Chamber of Commerce & Industry and is registered with the Abu Dhabi Municipality. We are related to a range of translation service offices located in Qatar, Syria, Lebanon and Jordan. Our translation services firm has a full-time, in-house translating staff of 8 linguists, editors, and account service personnel. Our translation service staff make a very important service commitment to their clients: That they will receive a translation/interpretation that truly reflects their words, thoughts and meaning. We understand the importance of overcoming language barriers and communicating your ideas across different cultures.')}
            </p>
            <img src={WhoWeAreTranslationFirm} alt='WhoWeAreTranslationFirm' className={styles['our-translation-firm__img']}/>
          </div>
        </Container>
      </section>

      <Container>
        <section className={styles['integrity']}>
          <div className={styles['integrity__content-wrapper']}>
            <h2 className={styles['integrity__title']}>{t('We Promote Integrity')}</h2>
            <p className={styles['integrity__description']}>{t('We ensure to have in place policies and procedures that guarantee integrity of everything we do in service of our customers, including information confidentiality, price transparency and fulfilment of all promises we give to our clients.')}</p>
          </div>
        </section>
      </Container>

        <Footer/>

    </motion.div>
    </>
  )
}

export default WhoWeAre