import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Blogs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading";
import { useRef } from 'react'
function Blogs() {
  const {t} =useTranslation()
  const imgRef= useRef()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [blogs,setBlogs]= useState<any>(localStorage.getItem('blogs')?JSON.parse(localStorage.getItem('blogs') as string) :[])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getAllBlogs(){
    axiosConfig.get(`/blogs`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setBlogs(res.data?.data?.data)
      localStorage.setItem('blogs',JSON.stringify(res.data?.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    getAllBlogs()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['blogs']}>
          <Container>
          {/* <h1 className={styles['blogs__title']}>{t('Blogs')}</h1> */}
              {
                blogs?.length!=0?
                  <>
                    <div className={styles['blog-card']}>
                      <div className={styles['blog-card__wrapper']}>
                      
                          <div className='position-relative'>
                              <div className={`loading__item`} ref={imgRef as any}>
                                  <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                              </div>
                              <img 
                                  src={blogs?.[0]?.cover_image} 
                                  className={styles['blog-card__image']} 
                                  alt='blog image'
                                  onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                                  />
                          </div>
                          <div className={styles['blog-card__content-wrapper']}>
                            <h2 className={styles['blog-card__content-title']}>{blogs?.[0]?.name}</h2>
                            <p className={styles['blog-card__description']} dangerouslySetInnerHTML={{__html: blogs?.[0]?.content}}/>
                            <Link to={`/blog/${blogs?.[0]?.id}`} className={styles['blog-card__link']}>{t('Read More')} &gt;&gt;</Link>
                          </div>
                      </div>
                    </div>
                    <div className={styles['blogs__wrapper']}>
                      {
                        blogs && blogs?.map((blog:any,index:any)=>(
                          index>0&&
                          <BlogCard blog={blog} key={index}/>
                          ))
                        }
                    </div>
                  </>
                :<h1 className={styles['blogs__title']}>{t('There Is No Blogs')}</h1>
                
              }
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Blogs