import styles from './SwiperHeader.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as LeftArrowTall} from 'assets/icons/leftArrowTall.svg'
import {ReactComponent as RightArrowTall} from 'assets/icons/rightArrowTall.svg'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { SwiperHeaderBGStyled } from './SwiperHeaderBGStyled'
import { SliderType } from 'views/Home/Home';
import {ReactComponent as HeaderRightArrowIcon} from 'assets/icons/headerRightArrowIcon.svg'

type ComponentCustomPropsTypes= {
    title:string,
    description:string,
    image:string,
    service_id:string,
}
function SwiperHeader(props:ComponentCustomPropsTypes) {
    const {title ,description,image,service_id}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['swiper-header']}>
            
            <SwiperHeaderBGStyled bgSrc={image}></SwiperHeaderBGStyled>
            <Container className='h-100'>
                <Row className='h-100'>
                    <Col lg='12' className='h-100'>
                        <div className={styles['header__content-wrapper']}>
                        <h1 className={styles['header__content-title']}>{title}</h1>
                        <p className={styles['header__content-description']}>{description}</p>
                        {service_id &&<Link to={`/product-details/${service_id}`}  className={styles['header__content-link']}>{t('Learn More')}<HeaderRightArrowIcon/></Link>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SwiperHeader