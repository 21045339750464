import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './LegalTranslation.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import {ReactComponent as LegalTranslationMedicalIcon} from 'assets/icons/legalTranslationMedicalIcon.svg'
import {ReactComponent as LegalTranslationMarriageIcon} from 'assets/icons/legalTranslationMarriageIcon.svg'
import {ReactComponent as LegalTranslationCourtIcon} from 'assets/icons/legalTranslationCourtIcon.svg'
import {ReactComponent as LegalTranslationBirthIcon} from 'assets/icons/legalTranslationBirthIcon.svg'
import {ReactComponent as LegalTranslationEducationIcon} from 'assets/icons/legalTranslationEducationIcon.svg'

function LegalTranslation() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['legal-translation']} id='legal-translation'>
        <Container className='h-100'>
          <h2 className={styles['legal-translation__title']}>{t('Poetry Translation:')}</h2>
          <p className={styles['legal-translation__description']}>{t('Unlock the beauty of verse across languages with our specialized poetry translation services. We understand that poetry is a delicate art form, and our team is dedicated to preserving the nuances, rhythm, and emotional depth of the original work. From classical to contemporary poetry, our translators ensure that the essence and soul of the poems transcend linguistic boundaries, allowing readers to experience the same poetic magic in the translated version.')}</p>
          <div className={styles['legal-translation__features-wrapper']}>  
            <div className={styles['legal-translation__feature-wrapper']}>
              <div className={styles['legal-translation__feature-icon-wrapper']}>
                <LegalTranslationMedicalIcon className={styles['legal-translation__feature-icon']}/>
              </div>
              <h3 className={styles['legal-translation__feature-title']}>{t('Medical Report Translation')}</h3>
            </div>
            <div className={styles['legal-translation__feature-wrapper']}>
              <div className={styles['legal-translation__feature-icon-wrapper']}>
                <LegalTranslationMarriageIcon className={styles['legal-translation__feature-icon']}/>
              </div>
              <h3 className={styles['legal-translation__feature-title']}>{t('Marriage Certificate Translation')}</h3>
            </div>
            <div className={styles['legal-translation__feature-wrapper']}>
              <div className={styles['legal-translation__feature-icon-wrapper']}>
                <LegalTranslationCourtIcon className={styles['legal-translation__feature-icon']}/>
              </div>
              <h3 className={styles['legal-translation__feature-title']}>{t('Court Paper Translation')}</h3>
            </div>
            <div className={styles['legal-translation__feature-wrapper']}>
              <div className={styles['legal-translation__feature-icon-wrapper']}>
                <LegalTranslationBirthIcon className={styles['legal-translation__feature-icon']}/>
              </div>
              <h3 className={styles['legal-translation__feature-title']}>{t('Birth Certificate Translation')}</h3>
            </div>
            <div className={styles['legal-translation__feature-wrapper']}>
              <div className={styles['legal-translation__feature-icon-wrapper']}>
                <LegalTranslationEducationIcon className={styles['legal-translation__feature-icon']}/>
              </div>
              <h3 className={styles['legal-translation__feature-title']}>{t('Education Certificate Translation')}</h3>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default LegalTranslation