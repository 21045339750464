import styles from './ServiceSmallCard.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as ServicesRightArrow} from 'assets/icons/servicesRightArrow.svg'
import { Link } from 'react-router-dom'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import ReactStars from 'react-stars'
// type ComponentCustomPropsTypes={
//     blog:BlogType
// }
// function BlogCard(props:ComponentCustomPropsTypes) {
function ServiceSmallCard(props:any) {
    const {service}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['service-card']}>
            <div className={styles['service-card__wrapper']}>
                <div className={styles['service-card__content-wrapper']}>
                    <h2 className={styles['service-card__content-title']}>{service?.title}</h2>
                    <p className={styles['service-card__content-discount']}>{service?.discount} % {t('Off')}</p>
                    <ReactStars
                        count={5}
                        // onChange={ratingChange}
                        edit={false}
                        size={24}
                        value={3}
                        color2={'#D09A2D'} 
                    />
                    <p className={styles['service-card__content-price']}>{t('AED')} {service?.price}</p>
                    <Link to={`/product-details/${service?.id}`} className={styles['service-card__link']}>{t('Select Option')}<ServicesRightArrow className={styles['service-card__link-icon']}/></Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceSmallCard