import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './WorkingHours.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import {ReactComponent as MailIcon} from 'assets/icons/mailIcon.svg'
import {ReactComponent as AddressIcon} from 'assets/icons/addressIcon.svg'
import { emailConnect, emailPattern } from 'utils/features'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'

function WorkingHours(props:any) {
  const {settings} =props
  const {t} =useTranslation()
  return (
    <>
      <section className={styles['contact-us']} id='contact-us'>
        {/* <SectionTitle title={`${t('Contact Us')}`}/> */}
        <Container className='h-100'>
          <Row className='h-100'>
            <Col lg='6' className='h-100 d-flex'>
              <div className={styles['contact-us__content-wrapper']}>
                <h2 className={styles['contact-us__title']}>{t('Working Hours')}</h2>
                <p className={styles['contact-us__descrption']}>{t('Available Online 24/7')}</p>
                <p className={styles['contact-us__descrption']}>{t('Office Hours: 7 Days a Week – 08:00 AM – 08:00 PM')}</p>
              </div>
            </Col>
            <Col lg='6'>
              <iframe 
              src={settings?.project_address_link?settings?.project_address_link:"https://www.google.com/maps/place/Al+Ghanem+Business+Center+Sharjah/@25.3310041,55.3703518,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f5bbb59bfffff:0x71dc7343f57ca315!8m2!3d25.3310041!4d55.3703518!16s%2Fg%2F11bx46ld_x?hl=en-AE&entry=ttu" }
              // width="600" 
              // height="450" 
              // style="border:0;" 
              // allowfullscreen=""
              className={styles['contact-us__map']} 
              loading="lazy" 
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default WorkingHours