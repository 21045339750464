import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Courses.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import { Link } from 'react-router-dom'
import CoursesCard from 'components/Global/Elements/Cards/CoursesCard/CoursesCard'
import {ReactComponent as ServicesPaginationRightArrow} from 'assets/icons/servicesPaginationRightArrow.svg'
import {ReactComponent as ServicesPaginationLeftArrow} from 'assets/icons/servicesPaginationLeftArrow.svg'
import {ReactComponent as InputSearchIcon} from 'assets/icons/inputSearchIcon.svg'
import {ReactComponent as ServicesHeaderHeartIcon} from 'assets/icons/servicesHeaderHeartIcon.svg'
import {ReactComponent as ServicesHeaderCartIcon} from 'assets/icons/servicesHeaderCartIcon.svg'
function Courses() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [courses,setCourses]= useState<BlogType[]>(localStorage.getItem('courses')?JSON.parse(localStorage.getItem('courses') as string) :[])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getAllCourses(){
    axiosConfig.get(`/courses/all-courses`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      setCourses(res.data?.data)
      localStorage.setItem('courses',JSON.stringify(res.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    getAllCourses()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['blogs']}>
          <Container>
          {/* <h1 className={styles['blogs__title']}>{t('Blogs')}</h1> */}
              {
                courses?.length!=0&&
                  <div className={styles['blog__header-wrapper']}>
                      <h2 className={styles['blog__header-title']}>{t('Courses')}</h2>
                      {/* <div className={styles['blog__header-input-wrapper']}>
                        <input type='text' className={styles['blog__header-input']} placeholder={`${t('Search here')}`}/>
                        <button type='button' className={styles['blog__header-search-input']}>
                          <InputSearchIcon/>
                        </button>
                      </div> */}
                      <div className={styles['services__sections-cart-wrapper']}>
                        {/* <Link to='/' className={styles['services__sections-cart-link']}>
                          <ServicesHeaderHeartIcon className={styles['services__sections-cart-link-icon']}/>
                          <span className={styles['services__sections-cart-link-count']}>20</span>
                        </Link> */}
                        {/* <Link to='/cart' className={styles['services__sections-cart-link']}>
                          <ServicesHeaderCartIcon className={styles['services__sections-cart-link-icon']}/>
                          <span className={styles['services__sections-cart-link-count']}>20</span>
                        </Link> */}
                      </div>
                  </div>
              }

            {/* <div className={`${styles['blog__header-input-wrapper']} ${styles['blog__header-input-wrapper--responsive']}`}>
              <input type='text' className={styles['blog__header-input']} placeholder={`${t('Search here')}`}/>
              <button type='button' className={styles['blog__header-search-input']}>
                <InputSearchIcon/>
              </button>
            </div> */}
              {
                courses?.length!=0?
                <div className={styles['blogs__wrapper']}>
                  {
                    courses && courses?.map((course:any)=>(
                        <CoursesCard 
                          course={course}
                        />
                    ))
                  }
                  </div>
                :<h1 className={styles['blogs__title']}>{t('There Is No Courses')}</h1>
              }
            {/* <div className={styles['services__pagination-wrapper']}>
              <button className={styles['services__pagination-link']}>
                <ServicesPaginationLeftArrow/>
              </button>
              <button className={`${styles['services__pagination-link']} ${styles['services__pagination-link--active']}`}>1</button>
              <button className={`${styles['services__pagination-link']}`}>2</button>
              <button className={`${styles['services__pagination-link']}`}>3</button>
              <button className={`${styles['services__pagination-link']}`}>4</button>
              <button className={styles['services__pagination-link']}>
                <ServicesPaginationRightArrow/>
              </button>
            </div> */}
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Courses