import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState ,useEffect} from 'react'
import styles from './Blog.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import blogImg from 'assets/imgs/blogImg.png'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link, useParams } from 'react-router-dom'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
function Blog() {
  const {t} =useTranslation()
  const params = useParams()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [blog ,setBlog] =useState<any>({} )
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getBlog(){
    axiosConfig.get(`/blogs/${params?.id}`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setBlog(res.data?.data)
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    getBlog()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['blog']}>
          <Container>
            <Row>
              <Col lg='12'>
                <div className={styles['blog__wrapper']}>
                  <img src={blog?.image} className={styles['blog__img']} alt='blog image'/>
                  {/* <div className={styles['blog__features-wrapper']}>
                    <div className={styles['blog__feature-wrapper']}>
                        <CalenderIcon className={styles['blog__feature-icon']}/>
                        <span className={styles['blog__feature-text']}>{blog?.date}</span>
                    </div>
                    <div className={styles['blog__feature-wrapper']}>
                        <LocationIcon className={styles['blog__feature-icon']}/>
                        <span className={styles['blog__feature-text']}>{blog?.location}</span>
                    </div>
                  </div> */}
                  <h1 className={styles['blog__title']}>{blog?.name}</h1>
                    <p className={styles['blog__description']} dangerouslySetInnerHTML={{__html: blog?.content}}/>
                  {/* <p className={styles['blog__description']}>The couple tied the knot in a quaint mission-style church before an outdoor reception beneath a translucent tent decked with twinkle lights. Trendy Eventos added rustic charm to the elegant tent with farmhouse tables, simple centerpieces of eucalyptus and white roses, and hand-carved wooden table numbers. The bride channeled her inner boho goddess with a stunning bell-sleeve BHLDN lace gown, which she paired with a gold and pearl crown that dreams are made of. Every individual detail that The Times We Have captured was beautiful on its own but, together, they created a true wedding masterpiece!</p>
                  <p className={styles['blog__description']}>The couple tied the knot in a quaint mission-style church before an outdoor reception beneath a translucent tent decked with twinkle lights. Trendy Eventos added rustic charm to the elegant tent with farmhouse tables, simple centerpieces of eucalyptus and white roses, and hand-carved wooden table numbers. The bride channeled her inner boho goddess with a stunning bell-sleeve BHLDN lace gown, which she paired with a gold and pearl crown that dreams are made of. Every individual detail that The Times We Have captured was beautiful on its own but, together, they created a true wedding masterpiece!</p>
                  <p className={styles['blog__description']}>The couple tied the knot in a quaint mission-style church before an outdoor reception beneath a translucent tent decked with twinkle lights. Trendy Eventos added rustic charm to the elegant tent with farmhouse tables, simple centerpieces of eucalyptus and white roses, and hand-carved wooden table numbers. The bride channeled her inner boho goddess with a stunning bell-sleeve BHLDN lace gown, which she paired with a gold and pearl crown that dreams are made of. Every individual detail that The Times We Have captured was beautiful on its own but, together, they created a true wedding masterpiece!</p>
                  <p className={styles['blog__description']}>The couple tied the knot in a quaint mission-style church before an outdoor reception beneath a translucent tent decked with twinkle lights. Trendy Eventos added rustic charm to the elegant tent with farmhouse tables, simple centerpieces of eucalyptus and white roses, and hand-carved wooden table numbers. The bride channeled her inner boho goddess with a stunning bell-sleeve BHLDN lace gown, which she paired with a gold and pearl crown that dreams are made of. Every individual detail that The Times We Have captured was beautiful on its own but, together, they created a true wedding masterpiece!</p> */}
                  {/* <form>
                    <h3 className={styles['blog__form-title']}>{t('Leave A comment')}</h3>
                    <div className={styles['blog__form-inputs-wrapper']}>
                      <div className={styles['blog__form-input-wrapper']}>
                        <input type='text' className={styles['blog__form-input']} id='nameInput' placeholder=' '/>
                        <label htmlFor='nameInput' className={styles['blog__form-label']}>{t('Your Name')}</label>
                      </div>
                      <div className={styles['blog__form-input-wrapper']}>
                        <input type='email' className={styles['blog__form-input']} id='emailInput' placeholder=' '/>
                        <label htmlFor='emailInput' className={styles['blog__form-label']}>{t('Your E-mail')}</label>
                      </div>
                    </div>
                      <div className={styles['blog__form-input-wrapper']}>
                        <input type='text' className={styles['blog__form-input']} id='commentInput' placeholder=' '/>
                        <label htmlFor='commentInput' className={styles['blog__form-label']}>{t('Your Comment')}</label>
                      </div>
                    <button className={styles['blog__form-submit']}>
                      <span className={styles['blog__form-submit-text']}>{t('Send')}</span>
                    </button>
                  </form> */}
                </div>
              </Col>
              {/* <Col lg='4'>
                <div className={styles['blog__side-wrapper']}>
                  <img src={blogCardImage} className={styles['blog__side-img']} alt='about us image'/>

                  <div className={styles['blog__side-content-wrapper']}>
                    <h3 className={styles['blog__side-title']}>{t('About Us')}</h3>
                    <p className={styles['blog__side-description']}>{t('to sit back and watch. We want them to lean forward. We want our work to add to their lives.We want them to lean forward.')}</p>
                  </div>

                  <div className={styles['blog__side-content-wrapper']}>
                    <h3 className={styles['blog__side-title']}>{t('Categories')}</h3>
                    <ul className={styles['blog__side-list']}>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Architecture</Link>
                      </li>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Design</Link>
                      </li>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Nature</Link>
                      </li>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Photography</Link>
                      </li>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Street</Link>
                      </li>
                      <li className={styles['blog__side-list-item']}>
                        <Link to='/' className={styles['blog__side-list-link']}>Technologies</Link>
                      </li>
                    </ul>
                  </div>
                  <div className={styles['blog__side-content-wrapper']}>
                    <h3 className={styles['blog__side-title']}>{t('Categories')}</h3>
                    <div className={styles['blog__side-images-wrapper']}>
                      <img src={blogCardImage} className={styles['blog__side-images-img']} alt='about us image'/>
                      <img src={blogCardImage} className={styles['blog__side-images-img']} alt='about us image'/>
                      <img src={blogCardImage} className={styles['blog__side-images-img']} alt='about us image'/>
                      <img src={blogCardImage} className={styles['blog__side-images-img']} alt='about us image'/>
                    </div>
                  </div>

                  <div className={styles['blog__side-content-wrapper']}>
                    <h3 className={styles['blog__side-title']}>{t('Tags')}</h3>
                    <div className={styles['blog__side-tags-wrapper']}>
                        <Link to='/' className={styles['blog__side-tag']}>Architecture</Link> 
                        <Link to='/' className={styles['blog__side-tag']}>Design</Link> 
                        <Link to='/' className={styles['blog__side-tag']}>Photography</Link> 
                        <Link to='/' className={styles['blog__side-tag']}>Nature</Link> 
                        <Link to='/' className={styles['blog__side-tag']}>Street</Link> 
                        <Link to='/' className={styles['blog__side-tag']}>Technologies</Link> 
                    </div>
                  </div>

                </div>
              </Col> */}
            </Row>
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Blog