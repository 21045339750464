import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './BillingAddressWrapper.module.css'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading";
function BillingAddressWrapper({data ,handleChange ,errors,isLoading,handleSubmit}) {
  const {t} = useTranslation()
  
  return (
    <section className={styles['add-item__wrapper']}>
      <h2 className={styles['add-item__title']}>{t('BILLING DETAILS')}</h2>
      <form className={styles['add-item__form']}>
        <Row>
          <Col lg='12'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Name*')}`}
                id='fullName'
                name='fullName'
                value={data?.billing_address?.fullName}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.fullName && <p className={styles['add-item__input-error']} >{errors?.billing_address?.fullName}</p>}
            </div>
          </Col>
          <Col lg='12'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Company Name*')}`}
                id='company'
                name='company'
                value={data?.billing_address?.company}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.company && <p className={styles['add-item__input-error']} >{errors?.billing_address?.company}</p>}
            </div>
          </Col>
          <Col lg='12'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('House Number and Street Name*')}`}
                id='houseNumber'
                name='houseNumber'
                value={data?.billing_address?.houseNumber}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.houseNumber && <p className={styles['add-item__input-error']} >{errors?.billing_address?.houseNumber}</p>}
            </div>
          </Col>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Town / City*')}`}
                id='city'
                name='city'
                value={data?.billing_address?.city}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.city && <p className={styles['add-item__input-error']} >{errors?.billing_address?.city}</p>}
            </div>
          </Col>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Phone Number*')}`}
                id='phone'
                name='phone'
                value={data?.billing_address?.phone}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.phone && <p className={styles['add-item__input-error']} >{errors?.billing_address?.phone}</p>}
            </div>
          </Col>
          <Col lg='12'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Apartment, Suite, Unit, etc, (optional)')}`}
                id='apartment'
                name='apartment'
                value={data?.billing_address?.apartment}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.apartment && <p className={styles['add-item__input-error']} >{errors?.billing_address?.apartment}</p>}
            </div>
          </Col>
          {/* payment:{
      creditCard:'',
      nameOfOwner:'',
      cvv:'',
      expiryDate:'',
    }, */}
          <h2 className={styles['add-item__title']}>{t('Payment')}</h2>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Credit Card Number')}`}
                id='creditCard'
                name='creditCard'
                value={data?.billing_address?.creditCard}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.creditCard && <p className={styles['add-item__input-error']} >{errors?.billing_address?.creditCard}</p>}
            </div>
          </Col>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Name Of Owner')}`}
                id='nameOfOwner'
                name='nameOfOwner'
                value={data?.billing_address?.nameOfOwner}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.nameOfOwner && <p className={styles['add-item__input-error']} >{errors?.billing_address?.nameOfOwner}</p>}
            </div>
          </Col>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('CVV')}`}
                id='cvv'
                name='cvv'
                value={data?.billing_address?.cvv}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.cvv && <p className={styles['add-item__input-error']} >{errors?.billing_address?.cvv}</p>}
            </div>
          </Col>
          <Col lg='6'>
            <div className={styles['add-item__input-wrapper']}>
              <input 
                type='text' 
                className={styles['add-item__input']} 
                placeholder={`${t('Expiary date')}`}
                id='expiryDate'
                name='expiryDate'
                value={data?.billing_address?.expiryDate}
                onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
              />
              {errors?.billing_address?.expiryDate && <p className={styles['add-item__input-error']} >{errors?.billing_address?.expiryDate}</p>}
            </div>
          </Col>
          <p className={styles['add-item__description']}> 
            {t('Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.')}
          </p>
          <div className={styles['add-item__terms-wrapper']} >
            <input type='checkbox' className={styles['add-item__terms-input']} id='terms'/> 
            <label htmlFor='terms' className={styles['add-item__terms-label']}>{t('I have read and agree to the website terms and conditions *')}</label>
          </div>
          <button type='button' className={styles['add-item__submit']} onClick={handleSubmit} disabled={isLoading}>
            {
              isLoading?
              <ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
              :
              <>{t('Place Order')} </>
            }
          </button> 
          {/* <Link to='/success-order' className={styles['add-item__submit']}>{t('Place Order')}</Link> */}
        </Row>

      </form>
    </section>
  )
}

export default BillingAddressWrapper