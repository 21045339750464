import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './OurServices.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import minstiryOfJusticePadge from 'assets/imgs/minstiryOfJusticePadge.png'
import IOSPadgeImage from 'assets/imgs/IOSPadgeImage.png'
import ourServicesImage from 'assets/imgs/ourServicesImage.jpg'
import { ServiceType} from 'views/Home/Home'
import {ReactComponent as OurServicesBrainIcon} from 'assets/icons/ourServicesBrainIcon.svg'
import {ReactComponent as OurServicesShieldIcon} from 'assets/icons/ourServicesShieldIcon.svg'
import {ReactComponent as OurServicesTrueIcon} from 'assets/icons/ourServicesTrueIcon.svg'
import ReactLoading from "react-loading";

function OurServices() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['our-services']} id='our-services'>
        <Container className='h-100'>
            <Row>
              <Col lg='5'>
                <div className='position-relative'>
                  <div className={`loading__item`} id={`loading__item-our-service`}>
                    <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                  </div>
                  <img 
                    src={ourServicesImage} 
                    className={styles['our-services__image']} 
                    alt='section Padge'
                    onLoad={(e)=>(document.getElementById(`loading__item-our-service`) as any).classList.add('d-none')}
                    />
                </div>
              </Col>
              <Col lg='7'>
                <h3 className={styles['our-services__title']}>{t('Our Services Stand Out For Being...')}</h3>
                <div className={styles['our-services__features-wrapper']}>  
                  <div className={styles['our-services__feature-wrapper']}>
                    <div className={styles['our-services__feature-title-wrapper']}>
                      <OurServicesTrueIcon className={styles['our-services__feature-title-icon']}/>
                      <h3 className={styles['our-services__feature-title']}>{t('Faster...')}</h3>
                    </div>
                    <p className={styles['our-services__feature-text']}>{t('We value your time! Translation deadline is strictly observed. Your translation is done when you need it as we are keen on offering fast language translation solutions to our local and international clients. Through our branches covering the UAE and online store, we respond to your translation needs at any time any anywhere.')}</p>
                  </div>
                  <div className={styles['our-services__feature-wrapper']}>
                    <div className={styles['our-services__feature-title-wrapper']}>
                      <OurServicesShieldIcon className={styles['our-services__feature-title-icon']}/>
                      <h3 className={styles['our-services__feature-title']}>{t('Better...')}</h3>
                    </div>
                    <p className={styles['our-services__feature-text']}>{t('We value your time! Translation deadline is strictly observed. Your translation is done when you need it as we are keen on offering fast language translation solutions to our local and international clients. Through our branches covering the UAE and online store, we respond to your translation needs at any time any anywhere.')}</p>
                  </div>
                  <div className={styles['our-services__feature-wrapper']}>
                    <div className={styles['our-services__feature-title-wrapper']}>
                      <OurServicesBrainIcon className={styles['our-services__feature-title-icon']}/>
                      <h3 className={styles['our-services__feature-title']}>{t('Smarter...')}</h3>
                    </div>
                    <p className={styles['our-services__feature-text']}>{t('We value your time! Translation deadline is strictly observed. Your translation is done when you need it as we are keen on offering fast language translation solutions to our local and international clients. Through our branches covering the UAE and online store, we respond to your translation needs at any time any anywhere.')}</p>
                  </div>
                </div>
              </Col>
            </Row>
          {/* </div> */}
        </Container>
      </section>
    </>
  )
}

export default OurServices