import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './ContactUs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import {ReactComponent as ContactUsCallUscon} from 'assets/icons/contactUsCallUscon.svg'
import {ReactComponent as ContactUsEmailIcon} from 'assets/icons/contactUsEmailIcon.svg'
import {ReactComponent as ContactUsTrollcon} from 'assets/icons/contactUsTrollcon.svg'
import {ReactComponent as ContactUsWhatsappcon} from 'assets/icons/contactUsWhatsappcon.svg'
import { emailConnect, emailPattern } from 'utils/features'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ReactLoading from "react-loading";
import homeHeaderBG from 'assets/imgs/homeHeaderBG.png'
import ContactUsForm from 'components/Global/Elements/ContactUsForm/ContactUsForm'

type ComponentPropsType ={
  settings:any,
}
function ContactUs(props:ComponentPropsType) {
  const {settings} =props
  const {t} =useTranslation()
  const [isSubmitting,setIsSubmitting]=useState<boolean>(false)
  const [name,setName] = useState<string>('')
  const [email,setEmail] = useState<string>('')
  const [phone,setPhone] = useState<string>('')
  const [message,setMessage] = useState<string>('')
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Contact Name Is Required'))
      status=false
    }
    if(!message){
      toast.error(t('Contact Message Is Required'))
      status=false
    }
    if((!emailPattern.test(email))){
      toast.error(t('Email Must Be Valid Email'))
      status=false
    }
    return status
  }

  function sendMessage(){
    if(!validateInputs()){
      return
    }
    setIsSubmitting(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('email',email)
    formData.append('message',message)
    formData.append('phone',phone)

    axiosConfig.post(`/contact-us/create-contact-us`,formData).then(res=>{
        setIsSubmitting(false)
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        toast.success(t(`Contact Us Added Successfully`))
      }).catch(err=>{
        setIsSubmitting(false)
        toast.error(t('Something went wrong'))
      })
  }
  return (
    <>
      <section className={styles['contact-us']} id='contact-us'>
        {/* <SectionTitle title={`${t('Contact Us')}`}/> */}
        
        <Container>
          <Row>
            <Col lg='6' className='m-auto'>
              <div className={styles['contact-us__content-wrapper']}>
                <h1 className={styles['contact-us__title']}>{t('Contact Us')}</h1>
                <p className={styles['contact-us__description']}>{t("Can't wait? We are all in a hurry, right? So please contact us through WhatsApp, email, phone or live-chat so we can make all required arrangements to give you a quote and determine your exact needs to be able to satisfy them to the highest level. We regularly charge per page or word but talk to us as maybe we can give you better options that suit your project and budget.")}</p>
              </div>
            </Col>
            <Col lg='6'>
              <ContactUsForm/>
            </Col>
            {/* <Col lg='5'>
              <form>
                <h3 className={styles['contact-us__form-title']}>{t('Mail Me')}</h3>
                <div className={styles['contact-us__form-inputs-wrapper']}>
                  <div className={styles['contact-us__form-input-wrapper']}>
                    <input 
                      type='text' 
                      value={name}
                      onChange={(e)=>{setName(e.target.value)}}
                      className={styles['contact-us__form-input']} 
                      id='nameInput' 
                      placeholder='Name'
                    />
                  </div>
                  <div className={styles['contact-us__form-input-wrapper']}>
                    <input 
                      type='email' 
                      value={email}
                      onChange={(e)=>{setEmail(e.target.value)}}
                      className={styles['contact-us__form-input']} 
                      id='emailInput' 
                      placeholder='Email'
                    />
                  </div>
                </div>
                  <div className={styles['contact-us__form-input-wrapper']}>
                    <input 
                      type='text' 
                      value={phone}
                      onChange={(e)=>{setPhone(e.target.value)}}
                      className={styles['contact-us__form-input']} 
                      id='phone' 
                      placeholder='phone'
                    />
                  </div>
                  <div className={styles['contact-us__form-input-wrapper']}>
                    <textarea 
                      value={message}
                      onChange={(e)=>{setMessage(e.target.value)}}
                      className={`${styles['contact-us__form-input']} ${styles['contact-us__form-input--textarea']}`} 
                      id='messageInput' 
                      placeholder='Message'
                      rows={5}
                    ></textarea>
                  </div>
                <button
                  type="button"
                  onClick={sendMessage}
                  disabled={isSubmitting}
                  className={styles['contact-us__form-submit']}>
                  <span className={styles['contact-us__form-submit-text']}>{t('Send Message')}</span>
                </button>
              </form>
            </Col> */}
            <div className={styles['contact-us__contacts-wrapper']}>
              <button role='button' onClick={()=>{window.open(`tel:${settings?.project_phone_number||'+380487055562'}`, '_self')}}  className={styles['contact-us__contact-wrapper']}>
                <ContactUsTrollcon className={styles['contact-us__contact-icon']}/>
                  <p className={styles['contact-us__contact-info-label']}>{t('Toll Free')}</p>
              </button>
              <a href={emailConnect(settings?.project_email_address||'support@promo-theme.com')}  className={styles['contact-us__contact-wrapper']}>
                <ContactUsEmailIcon className={styles['contact-us__contact-icon']}/>
                  <p className={styles['contact-us__contact-info-label']}>{t('Email')}</p>
              </a>
              <a href={settings?.project_address_link||"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3630.6998653640762!2d54.381793!3d24.495854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6665add4bd0d%3A0x817ddb997c3afe45!2sElite%20Legal%20Translation%20Services!5e0!3m2!1sen!2sus!4v1699272558660!5m2!1sen!2sus"} target='_blank' className={styles['contact-us__contact-wrapper']}>
                <ContactUsCallUscon className={styles['contact-us__contact-icon']}/>
                  <p className={styles['contact-us__contact-info-label']}>{t('Call-Us')}</p>
              </a>
              <a href={settings?.project_address_link||"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3630.6998653640762!2d54.381793!3d24.495854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6665add4bd0d%3A0x817ddb997c3afe45!2sElite%20Legal%20Translation%20Services!5e0!3m2!1sen!2sus!4v1699272558660!5m2!1sen!2sus"} target='_blank' className={styles['contact-us__contact-wrapper']}>
                <ContactUsWhatsappcon className={styles['contact-us__contact-icon']}/>
                  <p className={styles['contact-us__contact-info-label']}>{t('Whatsapp-Us')}</p>
              </a>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ContactUs