import styled from 'styled-components'

export const SwiperHeaderBGStyled:any = styled.div`
    /* background: url(${(props:any)=>props?.bgSrc}) #000000ba; */
    background: url(${(props:any)=>props?.bgSrc});
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    inset: 0;
    z-index: -1;
    /* filter: blur(10px); */
    /* &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0; 
    } */
`