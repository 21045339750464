import styles from './BlogCard.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import ReactLoading from "react-loading";
import { useRef } from 'react'
// type ComponentCustomPropsTypes={
//     blog:BlogType
// }
// function BlogCard(props:ComponentCustomPropsTypes) {
function BlogCard(props:any) {
    const {blog}= props
    const {t} =useTranslation()
    const imgRef= useRef()

    return (
        <div className={styles['blog-card']}>
            <Link to={`/blog/${blog?.id}`} className={styles['blog-card__link']}></Link>
            <div className={styles['blog-card__wrapper']}>
                <div className='position-relative'>
                    <div className={`loading__item`} ref={imgRef as any}>
                        <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                    </div>
                    <img 
                        src={blog?.cover_image} 
                        className={styles['blog-card__image']} 
                        alt='blog image'
                        onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                    />
                </div>
                {/* <img src={blog?.cover_image} className={styles['blog-card__image']} alt='blog image'/> */}
                <div className={styles['blog-card__content-wrapper']}>
                    <h2 className={styles['blog-card__content-title']}>{blog?.name}</h2>
                    
                    <p className={styles['blog-card__description']} dangerouslySetInnerHTML={{__html: blog?.content}}/>
                    
                    <Link to={`/blog/${blog?.id}`} className={styles['blog-card__link']}>Read More &gt;&gt;</Link>
                </div>
            </div>
        </div>
    )
}

export default BlogCard