import React from 'react'
import { useTranslation } from 'react-i18next'
// import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Col, Container, Row } from 'react-bootstrap'
import ShoppingCartEmpty from 'components/ShoppingCart/ShoppingCartEmpty/ShoppingCartEmpty'
import ShoppingCartTable from 'components/ShoppingCart/ShoppingCartTable/ShoppingCartTable'
import ShoppingCartTotal from 'components/ShoppingCart/ShoppingCartTotal/ShoppingCartTotal'
import styles from './CheckOut.module.css'
import { Link, useNavigate } from 'react-router-dom'
import ShoppingCartNavBar from 'components/Global/Elements/ShoppingCartNavBar/ShoppingCartNavBar'
import CheckOutForm from 'components/Checkout/CheckOutForm/CheckOutForm'
import CheckOutTotal from 'components/Checkout/CheckOutTotal/CheckOutTotal'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { emailPattern, phonePattern } from 'utils/features'
import { axiosConfig } from 'utils/axiosConfig'
import { changeCartItems } from 'reduxStore/Global/GlobalActions'
import FailureAlert from 'components/Global/Elements/Alerts/FailureAlert/FailureAlert'
import CheckOutAccordion from 'components/Checkout/CheckOutAccordion/CheckOutAccordion'
import { toast } from 'react-toastify'
function CheckOut() {
  const {t} =useTranslation()
  const navigate = useNavigate()
  const selector = useSelector(state=>state?.GlobalReducer)
  const dispatch = useDispatch()
  const [cart ,setCart] = useState({})
  const [paymentMethod ,setPaymentMethod] = useState('')

  const [shippinigType ,setShippinigType] = useState('free_scan')
 
  const [isLoading,setISloading]=useState(false)
  const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
  const [showAddress,setShowAddress]=useState(false)
  const [isError,setIsError] = useState(false)
  const [isFinished,setIsFinished] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [termsAccept,setTermsAccept] = useState(false)

  const [data,setData]=useState({
    payment:{
      creditCard:'',
      nameOfOwner:'',
      cvv:'',
      expiryDate:'',
    },
    billing_address:{
      fullName:'',
      company:'',
      houseNumber:'',
      apartment:'',
      streetAddress:'',
      city:'',
      phone:'',
      email:'',
      creditCard:'',
      nameOfOwner:'',
      cvv:'',
      expiryDate:'',
    }
  })

  const [errors,setErros]=useState({billing_address:{},payment:{}})
  
  function validateInputs(modififedData){
    let status = true
    let errors = {}
    if(!modififedData?.billing_address?.creditCard){
        errors = {...errors,billing_address:{...errors?.billing_address,creditCard:t('Credit Card Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.nameOfOwner){
        errors = {...errors,billing_address:{...errors?.billing_address,nameOfOwner:t('Name Of Owner Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.cvv){
        errors = {...errors,billing_address:{...errors?.billing_address,cvv:t('CVV Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.expiryDate){
        errors = {...errors,billing_address:{...errors?.billing_address,expiryDate:t('Expiry Date Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.fullName){
        errors = {...errors,billing_address:{...errors?.billing_address,fullName:t('Name Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.company){
        errors = {...errors,billing_address:{...errors?.billing_address,company:t('Company Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.houseNumber){
        errors = {...errors,billing_address:{...errors?.billing_address,houseNumber:t('House Number and Street Name Is Required')}}
        status=false
    }
    if(!modififedData?.billing_address?.city){
        errors = {...errors,billing_address:{...errors?.billing_address,city:t('City Is Required')}}
        status=false
    }
    if(modififedData?.billing_address?.email&&(!emailPattern.test(modififedData?.billing_address?.email))){
        errors = {...errors,billing_address:{...errors?.billing_address,email:t('Email Must Be Valid Email')}}
        status=false
    }
    // if(!phonePattern.test(modififedData?.billing_address?.phone)){
    if(!modififedData?.billing_address?.phone){
        errors = {...errors,billing_address:{...errors?.billing_address,phone:t('Phone Is Required')}}
        status=false
    }
    // if(!modififedData?.billing_address?.streetAddress){
    //     errors = {...errors,billing_address:{...errors?.billing_address,streetAddress:t('Street Address Is Required')}}
    //     status=false
    // }
    // if(showAddress){
    //     if(!modififedData?.shipping_address?.fullName){
    //         errors = {...errors,shipping_address:{...errors?.shipping_address,fullName:t('Name Is Required')}}
    //         status=false
    //     }
    //     if(!modififedData?.shipping_address?.city){
    //         errors = {...errors,shipping_address:{...errors?.shipping_address,city:t('City Is Required')}}
    //         status=false
    //     }
    //     if(!modififedData?.shipping_address?.streetAddress){
    //         errors = {...errors,shipping_address:{...errors?.shipping_address,streetAddress:t('Street Address Is Required')}}
    //         status=false
    //     }
    //     if(!modififedData?.shipping_address?.state){
    //         errors = {...errors,shipping_address:{...errors?.shipping_address,state:t('State Is Required')}}
    //         status=false
    //     }
    // }
    setErros(errors)
    setIsError(!status)
    setErrorMessage(t('Please Fill All Required Fields'))
    return status
  }

  function handleChange(value , name ,parentObject){
      let modififedData = {...data}
      modififedData[parentObject][name] = value
      if(name=='phone' &&isNaN(value)){
          return
      }
      if(isSubmittedBefore){
          validateInputs(modififedData)
      }
      setData(modififedData)
  }

  function handleSubmit(){
    setIsSubmittedBefore(true)
    setIsError(false)
    if(!validateInputs(data)){
      window.scrollTo(0,0)
      return
    }
    // if(!paymentMethod){
    //   setIsError(true)
    //   setErrorMessage(t('Payment Method Is Required'))
    //   return
    // }
    setISloading(true)
    let formData = new FormData()
    
    formData.append('billing_address_name',data?.billing_address?.fullName)
    formData.append('billing_address_company',data?.billing_address?.company)
    formData.append('billing_address_house_number',data?.billing_address?.houseNumber)
    formData.append('billing_address_apartment',data?.billing_address?.apartment)
    formData.append('billing_address_street_address',data?.billing_address?.streetAddress)
    formData.append('billing_address_city',data?.billing_address?.city)
    formData.append('billing_address_phone',data?.billing_address?.phone)
    formData.append('billing_address_email',data?.billing_address?.email)
    formData.append('billing_address_credit_card',data?.billing_address?.creditCard)
    formData.append('billing_address_name_of_owner',data?.billing_address?.nameOfOwner)
    formData.append('billing_address_cvv',data?.billing_address?.cvv)
    formData.append('billing_address_expiry_date',data?.billing_address?.expiryDate)

    formData.append('unique_identifier',localStorage.getItem('unique_identifier'))
    formData.append('shipping_type',shippinigType)

    cart?.products &&cart?.products.forEach((product,index)=>{
      product?.type === "service" && formData.append(`services[${index}][service_id]`,product?.product_id)
      product?.type === "course" && formData.append(`services[${index}][course_id]`,product?.course_id)
      formData.append(`services[${index}][type]`,product?.type)
      formData.append(`services[${index}][quantity]`,product?.quantity || 1)
      product?.delivery_options && formData.append(`services[${index}][delivery_options]`,product?.delivery_options)
      product?.to_language && formData.append(`services[${index}][to_language]`,product?.to_language)
      product?.file && formData.append(`services[${index}][file]`,product?.file)
    })
    setIsFinished(true)
    axiosConfig.post('/orders/create-order',formData,{
      headers:{
        authorization:`Bearer ${selector?.token}`
      }
    }).then(async(res)=>{
      setISloading(false)
      setIsError(false)
      setIsFinished(false)
      dispatch(changeCartItems({total_quantity:0,total_price:0,sub_total_price:0,products:[]}))

        if(res.data.data?.payment_method=='online'){
          // window.reload()
          window.location.replace(`/payment/${res.data.data._id}`);
          // navigate(`/payment/${res.data.data._id}`)
        }else{
          // if(selector?.token){
          //   navigate(`/my-account/orders`)
          // }else{
          // }
          navigate(`/success-order`)
        }
      }).catch(err=>{
        setIsFinished(false)
        setIsError(true)
        window?.scrollTo(0,0)
        if (err?.response?.data?.data?.is_logged_required){
          navigate('/login')
          toast?.error(err?.response?.data?.message)
        }
        setErrorMessage(err?.response?.data?.message)
        setISloading(false)
    })
  }

  useEffect(()=>{
    setCart(selector?.cart)
    if(selector?.settings?.is_cash_payment_active=='1'){
      setPaymentMethod('cash')
    }
    if(selector?.settings?.is_online_payment_active=='1'){
      setPaymentMethod('online')
    }
    if(selector?.user){
      setData({
        shipping_address:{
          fullName:selector?.user?.shipping_address?.name,
          city:selector?.user?.shipping_address?.city,
          streetAddress:selector?.user?.shipping_address?.street_address,
          state:selector?.user?.shipping_address?.state,
        },
        billing_address:{
          fullName:selector?.user?.billing_address?.name,
          city:selector?.user?.billing_address?.city,
          streetAddress:selector?.user?.billing_address?.street_address,
          email:selector?.user?.billing_address?.email,
          phone:selector?.user?.billing_address?.phone
        }
      })
    }
  },[selector])

  useEffect(()=>{
    if(!isFinished){
      if(selector?.cart?.products?.length==0){
        navigate('/cart')
      }
    }
  },[selector])
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        {/* <MetaTags>
              <title>{t('Cart -RD.Aroma')}</title>
        </MetaTags> */}
        <div className={styles['cart']}>
          
        </div>
        <ShoppingCartNavBar isCheckOut={true}/>
        <section className={styles['cart__wrapper']}>
          <Container className={styles['cart__wrapper-container']}>
          {/* <CheckOutAccordion/> */}
          {isError &&<FailureAlert message={errorMessage}/>}
              <Row className={styles['cart__wrapper-row']}>
                <h2 className={styles['add-item__title']}>{t('Customer information')}</h2>
                <form className={styles['add-item__form']}>
                  <Row>
                    <Col lg='12'>
                      <div className={styles['add-item__input-wrapper']}>
                        <input 
                          type='text' 
                          className={styles['add-item__input']} 
                          placeholder={`${t('Email Address*')}`}
                          id='email'
                          name='email'
                          value={data?.billing_address?.email}
                          onChange={(e)=>{handleChange(e.target.value,e.target.name,'billing_address')}}
                        />
                        {errors?.billing_address?.email && <p className={styles['add-item__input-error']} >{errors?.billing_address?.email}</p>}
                      </div>
                    </Col>
                  </Row>

                </form>
                <Col lg='8' className='mb-5'>
                  <CheckOutForm 
                    cart={cart} 
                    data={data} 
                    handleChange={handleChange} 
                    validateInputs={validateInputs} 
                    errors={errors}
                    isLoading={isLoading}
                    showAddress={showAddress}
                    setShowAddress={setShowAddress}
                    handleSubmit={handleSubmit}
                  />
                </Col>
                <Col lg='4' className='mb-5'>
                  <CheckOutTotal 
                    cart={cart} 
                    shippinigType={shippinigType}
                    setShippinigType={setShippinigType} 
                    selector={selector}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
          </Container>
        </section>
    </motion.div>
    </>
  )
}

export default CheckOut