import React,{ useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
// import NavBar from 'components/Global/Layout/NavBar/NavBar';
// import SideBar from 'components/Global/Layout/SideBar/SideBar';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Home from './views/Home/Home';
import { axiosConfig } from 'utils/axiosConfig';
// import Loading from 'components/Global/Elements/Loading/Loading';
import { useReducer } from 'react';
// import { changeAllCarsAction, changeBrandAction, changeGeneralSettingsAction, changeTypeAction } from 'reduxStore/Global/GlobalActions';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from 'components/Global/Layout/NavBar/NavBar';
import AOS from "aos";
import "aos/dist/aos.css";
import Blogs from 'views/Blogs/Blogs';
import Blog from 'views/Blog/Blog';
import SideBar from 'components/Global/Layout/SideBar/SideBar';
import ChatItem from 'components/Global/Elements/ChatItem/ChatItem';
// import { changeGeneralSettingsAction } from 'reduxStore/Global/GlobalActions';
import { useCookies } from 'react-cookie';
import WhoWeAre from 'views/WhoWeAre/WhoWeAre';
import HowWeWork from 'views/HowWeWork/HowWeWork';
import ContactUsPage from 'views/ContactUs/ContactUs';
import WhyUs from 'views/WhyUs/WhyUs';
import OurServices from 'views/OurServices/OurServices';
import Services from 'views/Services/Services';
import ShoppingCart from 'views/ShoppingCart/ShoppingCart';
import CheckOut from 'views/CheckOut/CheckOut';
import PaymentView from 'views/Payment/PaymentView';
import ProductDetails from 'views/ProductDetails/ProductDetails';
import SuccessOrder from 'views/SuccessOrder/SuccessOrder';
import CourseDetails from 'views/Course/CourseDetails/CourseDetails';
import CoursesPaymentView from 'views/Course/CoursesPayment/CoursesPaymentView';
import Courses from 'views/Course/Courses/Courses';
import CoursesSuccessOrder from 'views/Course/CoursesSuccessOrder/CoursesSuccessOrder';
import CoursesShoppingCart from 'views/Course/CoursesShoppingCart/CoursesShoppingCart';
import CoursesCheckOut from 'views/Course/CoursesCheckOut/CoursesCheckOut';
import Register from 'views/Register/Register';
import Login from 'views/Login/Login';
import { changeCartItems, changeSettings } from 'reduxStore/Global/GlobalActions';
import { v4 as uuidv4 } from 'uuid';
import Account from 'views/Account/Account';
import DashboardWrapper from 'components/Account/DashboardWrapper/DashboardWrapper';
import EditAccountWrapper from 'components/Account/EditAccountWrapper/EditAccountWrapper';
import Authed from 'utils/Authed';
import OrdersWrapper from 'components/Account/OrdersWrapper/OrdersWrapper';
import OrdersDetailsWrapper from 'components/Account/OrdersDetailsWrapper/OrdersDetailsWrapper';
import AboutUs from 'views/AboutUs/AboutUs';
import Terms from 'views/Terms/Terms';
function AnimatedRoutes() {
    const [isPageScrolling , setIsPageScrolling] =useState(false)
    const [isRouteExist,setIsRouteExist] =useState(false)
    const [isLoadingVisible,setIsLoadingVisible]=useState(false)
    const [isSideMenuShown,setIsSideMenuShown] =useState<boolean>(false)
    const websiteRoutes = ['our-team']
    const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
    const selector = useSelector((state:any)=>state?.GlobalReducer)

    const dispatch= useDispatch()
    const location = useLocation()
    useEffect(()=>{
      window.scrollTo(0,0)
  },[location])
  useEffect(() => {
    // AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.refresh();
  }, []);
  useEffect(()=>{
    let currentScroll = 0
    let scrollEvent = (e:any)=>{
      if(window.scrollY > currentScroll+100){
        setIsPageScrolling(true)
      }else{
        setIsPageScrolling(false)
      }
    }
    window.addEventListener('scroll',scrollEvent)
    return ()=> window.removeEventListener('scroll',scrollEvent)
  },[])
  
  function getGeneralSettings(){
    axiosConfig.get(`/settings`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      dispatch(changeSettings(res?.data?.data))
      // setSettings(res.data?.data)
      localStorage.setItem('settings',JSON.stringify(res.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  
  function generateUniqueIdentifer(){
    if(!localStorage.getItem('unique_identifier')){
      localStorage.setItem('unique_identifier',uuidv4());
    }
  }

  function getCartItems(){
    axiosConfig.get(`/cart/all-cart-items/${localStorage.getItem('unique_identifier')}`,{
      headers:{
        authorization: `Bearer ${selector?.token}`,
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      console.log('res.data.datares.data.data',res.data.data)
      dispatch(changeCartItems(res.data.data))
    }).catch(error=>{
      console.log(error.response)
    })
  }
  useEffect(()=>{
    generateUniqueIdentifer()
    getGeneralSettings()
    getCartItems()
  },[])
  useEffect(()=>{
    // setIsRouteExist( location.pathname.split('/').some(path=>websiteRoutes.includes(path)) )
  },[location]) 
  return (
    <>
      {/* {isLoadingVisible&&<Loading/>} */}
      <div className="App">
        <ToastContainer />
        {/* <BottomNavBar/> */}
        {/* <BottomNavBarLinks/> */}
        <NavBar isRouteExist={isRouteExist} isPageScrolling={isPageScrolling} isSideMenuShown={isSideMenuShown} setIsSideMenuShown={setIsSideMenuShown}/>
        <SideBar isSideMenuShown={isSideMenuShown} setIsSideMenuShown={setIsSideMenuShown}/>
        <ChatItem/>
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/courses' >
                  <Route index element={<Courses/>}></Route>
                  <Route path='course-details/:id' element={<CourseDetails/>}></Route>
                  <Route path='courses-cart' element={<CoursesShoppingCart/>}></Route>
                  <Route path='courses-check-out' element={<CoursesCheckOut/>}></Route>
                  <Route path='courses-success-order' element={<CoursesSuccessOrder/>}></Route>
                  <Route path='courses-payment/:id' element={<CoursesPaymentView/>}></Route>

                </Route>
                <Route path='/blogs' element={<Blogs/>}></Route>
                <Route path='/register' element={<Register/>}></Route>
                <Route path='/login' element={<Login/>}></Route>
                <Route path='/blog/:id' element={<Blog/>}></Route>
                <Route path='/who-we-are' element={<WhoWeAre/>}></Route>
                <Route path='/how-we-work' element={<HowWeWork/>}></Route>
                <Route path='/why-us' element={<WhyUs/>}></Route>
                <Route path='/contact-us' element={<ContactUsPage/>}></Route>
                <Route path='/about-us' element={<AboutUs/>}></Route>
                <Route path='/terms' element={<Terms/>}></Route>
                <Route path='/our-services' element={<OurServices/>}></Route>
                <Route path='/services' element={<Services/>}></Route>
                
                <Route path='/cart' element={<ShoppingCart/>}></Route>
                <Route path='/check-out' element={<CheckOut/>}></Route>
                <Route path='/success-order' element={<SuccessOrder/>}></Route>
                <Route path='/payment/:id' element={<PaymentView/>}></Route>

                <Route path="my-account" element={<Authed><Account/></Authed>}>
                  <Route index element={<DashboardWrapper />} />
                  <Route path="orders" element={<OrdersWrapper />} />
                  <Route path="orders/:id" element={<OrdersDetailsWrapper />} />
                  <Route path="edit-account" element={<EditAccountWrapper />} />
                </Route>

                <Route path='/product-details/:slug' element={<ProductDetails/>}></Route>
                <Route path='*' element={<ErrorPage/>}></Route>
            </Routes>
      </AnimatePresence>
        </div>
    </>
  )
}

export default AnimatedRoutes