import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './QualityWork.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import minstiryOfJusticePadge from 'assets/imgs/minstiryOfJusticePadge.png'
import IOSPadgeImage from 'assets/imgs/IOSPadgeImage.png'
import { ServiceType} from 'views/Home/Home'

function QualityWork() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['quality-work']} id='quality-work'>
        <Container className='h-100'>
            <Row>
              <Col lg='5'>
                <div className={styles['quality-work__title-wrapper']}>
                  <h2 className={styles['quality-work__title']}>{t('Quality Work With Accreditation')}</h2>
                </div>
              </Col>
              <Col lg='7'>
                <div className={styles['quality-work__features-wrapper']}>  
                  <div className={styles['quality-work__feature-wrapper']}>
                    <img src={minstiryOfJusticePadge} className={styles['quality-work__feature-image']} alt='section Padge'/>
                    <h3 className={styles['quality-work__feature-title']}>{t('Ministry of Justice-licensed')}</h3>
                    <p className={styles['quality-work__feature-text']}>{t('Ministry of Justice-Licensed Registration No 737. Offering a myriad of translation solutions')}</p>
                  </div>
                  {/* <div className={styles['quality-work__feature-wrapper']}>
                    <img src={IOSPadgeImage} className={styles['quality-work__feature-image']} alt='section Padge'/>
                    <h3 className={styles['quality-work__feature-title']}>{t('ISO Certified')}</h3>
                    <p className={styles['quality-work__feature-text']}>{t('Elite Legal Translation Services is an ISO-certified, quality-assured translation company. Achieving and maintaining this certification means that we produce translations and services recognized worldwide. Contact us for optimal quality translation services.')}</p>
                  </div> */}
                </div>
              </Col>
            </Row>
        </Container>
      </section>
    </>
  )
}

export default QualityWork