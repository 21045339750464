import React, { useEffect } from 'react'
import styles from './ShoppingCartTable.module.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import { Table } from 'react-bootstrap'
import productImage2 from 'assets/imgs/productImage2.jpg'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as MinusIcon} from 'assets/icons/minusIcon.svg'
import {ReactComponent as DeleteCircleIcon} from 'assets/icons/deleteCircleIcon.svg'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { axiosConfig } from 'utils/axiosConfig'
import { changeCartItems } from 'reduxStore/Global/GlobalActions'
import LoadingElement from 'components/Global/Elements/LoadingElement/LoadingElement'
import { useState } from 'react'
function ShoppingCartTable() {
  const {t} =useTranslation()
  const selector = useSelector(state=>state?.GlobalReducer)
  const [products , setProducts] = useState([])
  const dispatch = useDispatch()
  const [isDeletingElement,setIsDeletingElement] = useState(false)
  function deleteCartItem(id){
    setIsDeletingElement(true)
    axiosConfig.delete(`/cart/delete-cart/${id}?unique_identifier=${localStorage.getItem('unique_identifier')}`).then(res=>{
      setIsDeletingElement(false)
        dispatch(changeCartItems(res?.data?.data))
    }).catch(err=>{
      console.log(err)
    })
  }
  
  function updateCartItemQuantity(id,quantity){
    setIsDeletingElement(true)
    // let data ={
    //   quantity:quantity,
    //   cart_item_id:id
    // }
    let formData = new FormData()
      formData.append(`quantity`,quantity)
      formData.append(`cart_item_id`,id)
    // products?.forEach((product,index)=>{
    //   formData.append(`cart_item_id[${index}]`,product?.id)
    //   formData.append(`quantity[${index}]`,product?.quantity)
    // })
    axiosConfig.put(`/cart/update-cart-item-quantity`,formData).then(res=>{
      setIsDeletingElement(false)
      dispatch(changeCartItems(res?.data?.data))
    }).catch(err=>{
    setIsDeletingElement(false)
    })
  }
    
  function handleChangeQuantity(type,id){
    let modifiedProducts = [...products]
    let cartItemId =id
    let cartItemQuanity 
    modifiedProducts = modifiedProducts.map(product=>{
      if(product?.id == id){
        if(type=='prev'){
          if(product?.quantity >1){
            product['quantity'] --
          }
        }else{
            product['quantity'] ++
        }
        cartItemQuanity =product['quantity']
      }
      return product
    })

    setProducts(modifiedProducts)
    updateCartItemQuantity(cartItemId,cartItemQuanity)
  }

  useEffect(()=>{
    setProducts(selector?.cart?.products)
  },[selector])
  
  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>{t("PRODUCT")}</th>
            <th>{t("PRICE")}</th>
            <th>{t("QUANTITY")}</th>
            <th>{t("SUBTOTAL")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            products && products?.map(product=>(
              <tr key={product?.id} className='position-relative'>
                <td className='align-middle'>
                {isDeletingElement &&<div className={styles['table__delete-loading']}>
                  <LoadingElement/>
                </div>}
                  <div className={styles['cart__product-img-wrapper']}>
                    {/* <Link to={`/product-details/${product?.product_slug}`}>
                      <img src={product?.product_img} alt='product image' className={styles['cart__product-img']}/>
                    </Link> */}
                    {/* <div className={styles['cart__product-content-wrapper']}>
                      <Link to={`/product-details/${product?.product_slug}`} className={styles['cart__product-title']}>{t("Gift BOX")}</Link>
                      <Link to={`/product-details/${product?.product_slug}`} className={styles['cart__product-edit']}>{t("Edit Options")}</Link>
                    </div> */}
                    {
                      product?.type == 'service' ?
                      <div className={styles['cart__product-content-wrapper']}>
                        <h3 className={styles['cart__product-title']}>{product?.product_title}</h3>
                        <p className={styles['cart__product-desc']}>
                          <span className={styles['cart__product-title']}>{t('Type:')}</span>
                          {product?.category_title}
                        </p>
                        <h3 className={styles['cart__product-title']}>{t("Delivery Options:")}</h3>
                        <p className={styles['cart__product-desc']}>
                          {product?.delivery_options}
                        </p>
                        <h3 className={styles['cart__product-title']}>{t("To Language:")}</h3>
                        <p className={styles['cart__product-desc']}>
                          {product?.to_language}
                        </p>
                      </div>
                    :
                      <div className={styles['cart__product-content-wrapper']}>
                        <img src={product?.course_image} className={styles['cart__product-img']}/>
                        <h3 className={styles['cart__product-title']}>{product?.course_title}</h3>
                      </div>
                    }
                  </div>
                </td>
                <td className={`${styles['cart__product-text']} align-middle`}>
                  {product?.type == 'service' ? product?.product_price : product?.course_price} {t("AED")}
                </td>
                <td className='align-middle'>
                  <div className={styles['cart__product-quantity']}>
                    {
                      product?.type === "service" &&
                      <button className={styles['cart__product-quantity-button']} onClick={()=>{handleChangeQuantity('prev',product?.id)}}>
                        <span className={styles['cart__product-quantity-icon']}>-</span>
                      </button>
                    }
                    <input 
                      type='text' 
                      className={styles['cart__product-quantity-input']} 
                      value={product?.quantity ||1}
                    />
                    {
                      product?.type === "service" &&
                      <button className={styles['cart__product-quantity-button']} onClick={()=>{handleChangeQuantity('next',product?.id)}}>
                        <span className={styles['cart__product-quantity-icon']}>+</span>
                      </button>
                    }
                  </div>
                </td>
                <td className={`${styles['cart__product-text']} align-middle`}>
                  {(Number(product?.type == 'service' ? product?.product_price : product?.course_price) * Number(product?.quantity || 1))}
                  {t(" AED")}
                </td>
                <td className={`${styles['cart__product-text']} align-middle`}>
                  <button className={styles['cart__product-remove']} onClick={()=>{deleteCartItem(product?.id)}}>
                    <DeleteCircleIcon/>
                  </button>
                </td>
              </tr>
          ))
          }
        </tbody>
      </Table>
      {/* <div className={styles['cart__options-wrapper']}>
        <div className={styles['cart__copoun-wrapper']}>
          <input type='text' className={styles['cart__copoun-input']} placeholder={t('Coupon Code')}/>
          <button className={styles['cart__copoun-submit']}>{t('OK')}</button>
        </div>
        <button className={styles['cart__delete-all-button']}>
          <DeleteIcon className={styles['cart__delete-all-icon']}/>
          {t("CLEAR SHOPPING CART")}
        </button>
      </div> */}
    </div>
  )
}

export default ShoppingCartTable