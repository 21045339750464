import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './HowWeWork.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import whoWeAreWhatWeOfferImg from 'assets/imgs/whoWeAreWhatWeOfferImg.jpg'
import WhoWeAreTranslationFirm from 'assets/imgs/WhoWeAreTranslationFirm.png'
import Footer from 'components/Global/Layout/Footer/Footer'
function HowWeWork() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
 
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <header className={styles['how-we-work__header']}>
        <h1 className={styles['how-we-work__header--title']}>{t('How We Work')}</h1>
      </header>

      <section className={styles['how-we-work__features']}>
        <Container>
          <p className={styles['how-we-work__description']}>
            {t('It’s our understanding of your business needs, our commitment to your project, our sensitivity to cultural differences and our ‘can-do’ approach that set us apart from other translation companies. We set the trend in innovation, quality and service.')}
          </p>
          <p className={styles['how-we-work__description']}>
            {t('We offer high standard legal translations, driving license, legalization of documents, notary public applications, document attestation, interpreting and proofreading services.')}
          </p>

          <div className={styles['how-we-work__quality-wrapper']}>
            <h2 className={styles['how-we-work__quality-title']}>{t('Stages Involved Our Service Process​')}</h2>
            <p className={styles['how-we-work__quality-description']}>
              {t('Over the years, we’ve established the most effective workflow for managing translation projects, attesting files and providing unique services.')}
            </p>
          </div>

            <h2 className={styles['how-we-work__features-wrapper-title']}>{t('Our Workflow involves three major phases:')}</h2>
          <div className={styles['how-we-work__features-wrapper']}>
            <div className={styles['how-we-work__feature-wrapper']}>
              <h3 className={styles['how-we-work__feature-title']}>{t('Phase 1: Preparation')}</h3>
              <ul className={styles['how-we-work__features-list']}>
                <li className={styles['how-we-work__features-item']}>{t('Going fast through the document to assess the size, degree of technicality, time needed to translate/edit and the cost.')}</li>
                <li className={styles['how-we-work__features-item']}>{t('Setting the delivery deadline.')}</li>
                <li className={styles['how-we-work__features-item']}>{t('Selecting the translator/editor most competent to do the translation/editing Entering into the Elite System.')}</li>
              </ul>
            </div>
            <div className={styles['how-we-work__feature-wrapper']}>
              <h3 className={styles['how-we-work__feature-title']}>{t('Phase 2: Execution')}</h3>
              <ul className={styles['how-we-work__features-list']}>
                <li className={styles['how-we-work__features-item']}>{t('Starting with the translation by the translator selected.')}</li>
                <li className={styles['how-we-work__features-item']}>{t('Referring the translation to the checker/editor to review and revise the draft document.')}</li>
                <li className={styles['how-we-work__features-item']}>{t('Coordinating the document according to the client’s instructions in terms of page set-up, font size, type and incorporating any figures, tables, etc. to the satisfaction of the client.')}</li>
              </ul>
            </div>
            <div className={styles['how-we-work__feature-wrapper']}>
              <h3 className={styles['how-we-work__feature-title']}>{t('Phase 3: Completion')}</h3>
              <ul className={styles['how-we-work__features-list']}>
                <li className={styles['how-we-work__features-item']}>{t('Contacting the client to inform them of the completion of the work.')}</li>
                <li className={styles['how-we-work__features-item']}>{t('Handing over the completed version by e-mail, by hand or courier.')}</li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

        <Footer/>

    </motion.div>
    </>
  )
}

export default HowWeWork