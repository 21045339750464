import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Home.module.css'
// import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import AboutUs from 'components/Home/AboutUs/AboutUs'
import BeingInControl from 'components/Home/BeingInControl/BeingInControl'
import Header from 'components/Home/Header/Header'
import { axiosConfig } from 'utils/axiosConfig'
import OurTeam from 'components/Home/OurTeam/OurTeam'
import HomeBlogs, { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import Loading from 'components/Global/Elements/Loading/Loading'
import DidYouKnow from 'components/Home/DidYouKnow/DidYouKnow'
import WhatOurClientsSay from 'components/Home/WhatOurClientsSay/WhatOurClientsSay'
import QualityWork from 'components/Home/QualityWork/QualityWork'
import OurServices from 'components/Home/OurServices/OurServices'
import ServicesOneStop from 'components/Home/ServicesOneStop/ServicesOneStop'
import ServicesNeeded from 'components/Home/ServicesNeeded/ServicesNeeded'
import LegalTranslation from 'components/Home/LegalTranslation/LegalTranslation'
import TranslationCompany from 'components/Home/TranslationCompany/TranslationCompany'
import OnlineTranslation from 'components/Home/OnlineTranslation/OnlineTranslation'
import FreeSampleTranslation from 'components/Home/FreeSampleTranslation/FreeSampleTranslation'
import WorkingHours from 'components/Home/WorkingHours/WorkingHours'
import Footer from 'components/Global/Layout/Footer/Footer'
import axios from 'axios'
import Headere from 'components/Home/Headere/Header'

export type SliderType ={
  id?: string,
  title: string,
  category_title: string,
  welcome_description: string,
  welcome_title: string,
  video: string,
  mobile_video: string,
  image: string,
}
export type TeamMemberType ={
  id?:string,
  image:string,
  name:string,
  description:string,
  job_title:string,
}
export type ServiceType ={
  id?:string,
  image:string,
  title:string,
}
const SECTIONS_COUNT = 6
function Home() {
  const {t} =useTranslation()
  const location = useLocation()
  const selector = useSelector((state:any)=>state?.GlobalReducer)
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  const [isPageLoading ,setIsPageLoading] = useState(false)
  const [activeSection ,setActiveSection] = useState(1)
  const [sliders,setSliders]= useState<SliderType[]>(localStorage.getItem('sliders')?JSON.parse(localStorage.getItem('sliders') as string) :[])
  const [settings,setSettings]= useState<any[]>(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings') as string) :[])
  const [videos,setVideos]= useState<any[]>(localStorage.getItem('videos')?JSON.parse(localStorage.getItem('videos') as string) :[])
  // const [sliders,setSliders]= useState<any[]>(localStorage.getItem('sliders')?JSON.parse(localStorage.getItem('sliders') as string) :[])
  const [teamMembers,setTeamMembers]= useState<TeamMemberType[]>(localStorage.getItem('members')?JSON.parse(localStorage.getItem('members') as string) :[])
  const [services,setServices]= useState<ServiceType[]>(localStorage.getItem('services')?JSON.parse(localStorage.getItem('services') as string) :[])
  const [blogs,setBlogs]= useState<BlogType[]>(localStorage.getItem('blogs')?JSON.parse(localStorage.getItem('blogs') as string) :[])
  
  function getSliders(){
    axiosConfig.get(`/slider/all-sliders`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setSliders(res.data?.data)
      localStorage.setItem('sliders',JSON.stringify(res.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  function getVideos(){
    axiosConfig.get(`/video/all-videos`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setVideos(res.data?.data)
      localStorage.setItem('videos',JSON.stringify(res.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  function getServices(){
    axiosConfig.get(`/services/all-categories-services`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      let servicesData:any = []
      res?.data?.data?.forEach((val:any)=>{
        servicesData?.push(val?.services)
      })
      servicesData = servicesData?.flat()
      setServices(servicesData)
      localStorage.setItem('services',JSON.stringify(servicesData))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    // getGeneralSettings()
    getSliders()
    getVideos()
    getServices()
    setSettings(selector?.settings)
  },[selector])

  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <div className={`${styles['home']} js-home`}>
        {/* {isPageLoading&&<Loading/>} */}
        {/* <Header sliders = {sliders}/> */}
        <Headere sliders = {sliders}/>
        <BeingInControl videos={videos}/>
        {/* <DidYouKnow/> */}
        <WhatOurClientsSay/>
        <QualityWork/>
        <OurServices/>
        {/* <ServicesOneStop/> */}
        <ServicesNeeded services={services}/>
        <LegalTranslation/>
        <TranslationCompany/>
        <OnlineTranslation/>
        <FreeSampleTranslation/>
        <ContactUs settings={settings} />
        <WorkingHours settings={settings}/>
        <Footer />
      </div>
    </motion.div>
    </>
  )
}

export default Home