import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Login.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import { Link, useNavigate } from 'react-router-dom'
import CoursesCard from 'components/Global/Elements/Cards/CoursesCard/CoursesCard'
import {ReactComponent as ServicesPaginationRightArrow} from 'assets/icons/servicesPaginationRightArrow.svg'
import {ReactComponent as ServicesPaginationLeftArrow} from 'assets/icons/servicesPaginationLeftArrow.svg'
import {ReactComponent as InputSearchIcon} from 'assets/icons/inputSearchIcon.svg'
import {ReactComponent as ServicesHeaderHeartIcon} from 'assets/icons/servicesHeaderHeartIcon.svg'
import {ReactComponent as ServicesHeaderCartIcon} from 'assets/icons/servicesHeaderCartIcon.svg'
import { emailPattern } from 'utils/features'
import { useDispatch } from 'react-redux'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
import ReactLoading from "react-loading";
function Login() {
  const {t} =useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loginEmail ,setLoginEmail] = useState('')
  const [loginPassword ,setLoginPassword] = useState('')
  const [registerEmail ,setRegisterEmail] = useState('')
  const [errorMessage ,setErrorMessage] = useState('')
  const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
  const [isloading,setIsloading] = useState(false)
  const [isMessageError,setIsMessageError] = useState(false)
  const [isRegisterLoading,setIsRegisterLoading] = useState(false)
  const [isLoginLoading,setIsLoginLoading] = useState(false)
  const [isPasswordVisible , setIsPasswordVisible] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['i18next','token']);


  function login(){
    if(!emailPattern.test(loginEmail)){
      // setErrorMessage(t('Please provide a valid Login email address.'))
      setIsMessageError(true)
      return
    }
    let data ={
      email:loginEmail,
      password:loginPassword,
      unique_identifier:localStorage.getItem('unique_identifier')
    }
    setIsLoginLoading(true)
    axiosConfig.post('/user/login-user',data).then(res=>{
      setIsLoginLoading(false)
      setCookie('token',res.data.data?.token)
      dispatch(changeTokenAction(res.data.data?.token))
      dispatch(changeUserDetails(res.data.data?.user))
      // dispatch(changeTokenAction(res.data.data?.token))
      navigate('/my-account/edit-account')
    }).catch(err=>{
      setIsLoginLoading(false)
      setIsMessageError(true)
      setErrorMessage(err?.response?.data?.message)
    })
  }
  // function showHidePassword(){
  //   let passIcon = document.querySelector(`#Path_3314`)
  //   setIsPasswordVisible(prev=>!prev)
  //   passIcon.classList.toggle(styles['toggle-view-pass'])
  // }
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
    > 
      <section className={styles['login']}>
        <Container>
          <div className={styles['login-content-wrapper']}>
            <h1 className={styles['login__title']}>{t('Login to Elrasd')}</h1>
            <form className={styles['login__form-wrapper']}>
              <div className={styles['login__input-wrapper']}>
                <label className={styles['login__input-label']}>{t('Username')}</label>
                <input
                  type='email'
                  className={styles['login__input']}
                  placeholder={`${t('Enter Username')}`}
                  value={loginEmail}
                  onChange={e=>setLoginEmail(e.target.value)}
                />
              </div>
              <div className={styles['login__input-wrapper']}>
                <label className={styles['login__input-label']}>{t('Password')}</label>
                <input
                  type='password'
                  className={styles['login__input']}
                  placeholder={`${t('Enter Password')}`}
                  value={loginPassword}
                  onChange={e=>setLoginPassword(e.target.value)}
                />
              </div>
              <p className={styles['login__login-text']}>
                {t('Do not have account?')}
                <Link to='/register' className={styles['login__login-link']}>{t('Register Now')}</Link>
              </p>
              <button className={styles['login__submit']} type='button' onClick={login} disabled={isRegisterLoading||isLoginLoading}>
                {
                  isLoginLoading ?
                  <ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                  :
                  t('Login')
                }
              </button>
            </form>
          </div>
        </Container>
      </section>
      <Footer/>
    </motion.div>
    </>
  )
}

export default Login