import React from 'react'
import styles from './CheckOutTotal.module.css'
import { useTranslation } from 'react-i18next'
import ShoppingCartItem from 'components/Global/Elements/ShoppingCartItem/ShoppingCartItem'
import LoadingElement from 'components/Global/Elements/LoadingElement/LoadingElement'
import Payment from '../PaymentForm/Payment'
import { Link } from 'react-router-dom'

function CheckOutTotal({cart,selector ,shippinigType,setShippinigType}) {
    const {t}= useTranslation()
  return (
  <div className={`${styles['cart__totals-wrapper']} sticky-lg-top`}>
    <h2 className={styles['cart__totals-title']}>{t('Basket totals')}</h2>
    <div className={styles['cart__total-content-wrapper']}>
        <div className={styles['cart__total-sub-wrapper']}>
          <h3 className={styles['cart__total-title']}>{t('Subtotal')}</h3>
          <p className={styles['cart__total-price']}>{cart?.sub_total_price} {t('AED')}</p>
        </div>
        <div className={styles['cart__total-shipping-wrapper']}>
          <div>
            <h3 className={styles['cart__total-title']}>{t('Shipping')}</h3>
          </div>
          <div className={styles['cart__total-shipping-description-wrapper']}>
            <p className={styles['cart__total-price']}>{selector?.settings?.shipping_chargers} {t('AED')}</p>
            <div className={styles['cart__total-checkbox-wrapper']}>
              <input 
                type='radio' 
                name='delivery' 
                className={styles['cart__total-checkbox']} 
                id='home_delivery'
                value={'home_delivery'}
                checked={shippinigType === 'home_delivery'}
                onChange={(e)=>setShippinigType(e.target.value)}
              />
              <label className={styles['cart__total-label']} htmlFor='home_delivery'>
              {t('Home Delivery')} <span className={`${styles['cart__total-price--bold']}`}>AED {selector?.settings?.shipping_chargers}</span>
              </label>
            </div>
            <div className={styles['cart__total-checkbox-wrapper']}>
              <input 
                type='radio' 
                name='delivery' 
                className={styles['cart__total-checkbox']} 
                id='free_scan'
                value={'free_scan'}
                checked={shippinigType === 'free_scan'}
                onChange={(e)=>setShippinigType(e.target.value)}
              />
              <label className={styles['cart__total-label']} htmlFor='free_scan'>
                {t('Free Scanned')}
              </label>
            </div>
            <div className={styles['cart__total-checkbox-wrapper']}>
              <input 
                type='radio' 
                name='delivery' 
                className={styles['cart__total-checkbox']} 
                id='free_delivery'
                value={'free_delivery'}
                checked={shippinigType === 'free_delivery'}
                onChange={(e)=>setShippinigType(e.target.value)}
              />
              <label className={styles['cart__total-label']} htmlFor='free_delivery'>
                {t('Free Delivery')}
              </label>
            </div>
          </div>
        </div>
        <div className={styles['cart__total-wrapper']}>
          <h3 className={styles['cart__total-title']}>{t('Total')}</h3>
          <p className={`${styles['cart__total-price']} ${styles['cart__total-price--bold']}`}>{shippinigType === 'home_delivery'?Number(cart?.total_price+selector?.settings?.shipping_chargers):Number(cart?.total_price) } {t('AED')} <span className={styles['cart__total-vat']}>{t('(includes AED VAT)',{ vat_value: selector?.settings?.vat_value })}</span></p>
        </div>
        {/* <Link to='/check-out' className={styles['cart__total-check-out']}>{t('PROCEED TO CHECKOUT')}</Link> */}
        {/* <div className={styles['cart__coupoun-wrapper']}>
          <input type='text' placeholder={`${t('Coupon Code')}`} className={styles['cart__coupoun-input']}/>
          <button className={styles['cart__coupoun-apply']}>{t('Apply')}</button>
        </div> */}
        {/* <Link to='/check-out' className={styles['cart__total-shopping']}>{t('Proceed to checkout')}</Link> */}
      </div>
  </div>
  )
}

export default CheckOutTotal