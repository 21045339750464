import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './AboutUs.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { useCookies } from 'react-cookie'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from 'components/Global/Layout/Footer/Footer'

function AboutUs() {
  const {t} =useTranslation()
  const [staticPages,setStaticPages]= useState<any>(localStorage.getItem('static-pages')?JSON.parse(localStorage.getItem('static-pages') as string) :[])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  
  function getStaticContent(){
    axiosConfig.get(`/static-pages`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setStaticPages(res.data?.data)
      localStorage.setItem('static-pages',JSON.stringify(res.data?.data))
    }).catch(err=>{
        console.log(err)
    })
  }
  useEffect(()=>{
    // getGeneralSettings()
    getStaticContent()
  },[])

  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      
      <section className={styles['blog']}>
          <Container>
            <Row>
              <Col lg='12'>
                <div className={styles['blog__wrapper']}>
                  <h1 className={styles['blog__title']}>{t('About Us')}</h1>
                  <p className={styles['blog__description']} dangerouslySetInnerHTML={{__html: staticPages?.about_us}}/>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
    </motion.div>
    </>
  )
}

export default AboutUs