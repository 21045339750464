import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './ServicesNeeded.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import minstiryOfJusticePadge from 'assets/imgs/minstiryOfJusticePadge.png'
import IOSPadgeImage from 'assets/imgs/IOSPadgeImage.png'
import serviceNeededImg1 from 'assets/imgs/serviceNeededImg1.jpg'
import serviceNeededImg2 from 'assets/imgs/serviceNeededImg2.jpg'
import serviceNeededImg3 from 'assets/imgs/serviceNeededImg3.jpg'
import serviceNeededImg4 from 'assets/imgs/serviceNeededImg4.jpg'
import serviceNeededImg5 from 'assets/imgs/serviceNeededImg5.jpg'
import serviceNeededImg6 from 'assets/imgs/serviceNeededImg6.jpg'
import { ServiceType} from 'views/Home/Home'
import {ReactComponent as OurServicesBrainIcon} from 'assets/icons/ourServicesBrainIcon.svg'
import {ReactComponent as OurServicesShieldIcon} from 'assets/icons/ourServicesShieldIcon.svg'
import {ReactComponent as OurServicesTrueIcon} from 'assets/icons/ourServicesTrueIcon.svg'
import ServicesNeededCard from 'components/Global/Elements/ServicesNeededCard/ServicesNeededCard'

function ServicesNeeded(props:any) {
  const {services} = props
  const {t} =useTranslation()
  return (
    <>
      <section className={styles['services-needed']} id='services-needed'>
        <Container className='h-100'>
            <h3 className={styles['services-needed__title']}>{t('Services Needed by Almost Everyone')}</h3>
            <Row>
              {
                services && services?.map((service:any)=>(
                  <Col xl='4' lg='6' className='mb-4' key={service?.id}>
                    <ServicesNeededCard
                      id={service?.id}
                      title={service?.title}
                      description={service?.description}
                      image={service?.image}
                    />
                  </Col>
                ))
              }
              {/* <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Translation')}
                  description={t('Translation services must be precisely, properly and duly delivered. Without precise delivery of translation services, you will absolutely not be able to submit any paper to a government entity, especially if documents are issued in a language other than Arabic.')}
                  image={serviceNeededImg1}
                />
              </Col>
              <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Attestation')}
                  description={t('The attestations service we provide includes attesting all types of documents from the Ministry of Foreign Affairs, Ministry of Justice, Ministry of Education, Chamber of Commerce, Health Authorities and Embassies and Consulates of all countries.')}
                  image={serviceNeededImg2}
                />
              </Col>
              <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Driving License')}
                  description={t('Driving license translation & replacement is an option to obtain a driving license from UAE traffic authorities to replace a driving license with a UAE one. We are linked online with Traffic Departments to assist you translate and receive your driving license.')}
                  image={serviceNeededImg3}
                />
              </Col>
              <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Notary Public')}
                  description={t('Notary public applications include power of attorney, contracts and agreements, memorandum of association, declarations, acknowledgements, local service agent contracts and sole proprietorship contract.')}
                  image={serviceNeededImg4}
                />
              </Col>
              <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Interpreting')}
                  description={t('At Elite Legal Translation Services, we are very keen to provide you with top quality interpretation services, specifically designed to support you in your business meetings, conferences, events or any other context.')}
                  image={serviceNeededImg5}
                />
              </Col>
              <Col xl='4' lg='6' className='mb-4'>
                <ServicesNeededCard
                  title={t('Police Clearance')}
                  description={t('The police clearance certificate is an official document issued by the Ministry of Interior and police departments to ensure that a person doesn’t have a criminal record in the country. Contact us to apply for you today.')}
                  image={serviceNeededImg6}
                />
              </Col> */}
            </Row>
        </Container>
      </section>
    </>
  )
}

export default ServicesNeeded