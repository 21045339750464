import styles from './CoursesCard.module.css'
import { useTranslation } from 'react-i18next'
import CourseImage from 'assets/imgs/CourseImage.jpg'
import {ReactComponent as CoursesClockIcon} from 'assets/icons/CoursesClockIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import ReactLoading from "react-loading";
import { useRef } from 'react'
// type ComponentCustomPropsTypes={
//     blog:BlogType
// }
// function BlogCard(props:ComponentCustomPropsTypes) {
function CoursesCard(props:any) {
    const {course}= props
    const {t} =useTranslation()
    const imgRef= useRef()
    
    return (
        <div className={styles['course-card']}>
            <Link to={`/courses/course-details/${course?.id}`} className={styles['course-card__link']}></Link>
            <div className={styles['course-card__wrapper']}>
            
                <div className='position-relative'>
                    <div className={`loading__item`} ref={imgRef as any}>
                    <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                    </div>
                    <img 
                        src={course?.image} 
                        className={styles['course-card__image']} 
                        alt='course image'
                        onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                    />
                </div>
                {/* <img src={course?.image} className={styles['course-card__image']} alt='course image'/> */}
                <div className={styles['course-card__content-wrapper']}>
                    <h2 className={styles['course-card__content-title']}>{course?.title}</h2>
                    <div className={styles['course-card__info-wrapper']}>
                        <p className={styles['course-card__info']} >
                            <CoursesClockIcon/>{course?.total_time} {t('Hours')}
                        </p>
                        <p className={styles['course-card__info']} >
                            {course?.total_lectures} {t('Lectures')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoursesCard