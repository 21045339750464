import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Services.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import whyUsImg from 'assets/imgs/whyUsImg.jpg'
import whoWeAreWhatWeOfferImg from 'assets/imgs/whoWeAreWhatWeOfferImg.jpg'
import WhoWeAreTranslationFirm from 'assets/imgs/WhoWeAreTranslationFirm.png'
import Footer from 'components/Global/Layout/Footer/Footer'
import ServiceSmallCard from 'components/Global/Elements/Cards/ServiceSmallCard/ServiceSmallCard'
import { Link, useSearchParams } from 'react-router-dom'
import {ReactComponent as ServicesHeaderHeartIcon} from 'assets/icons/servicesHeaderHeartIcon.svg'
import {ReactComponent as ServicesHeaderCartIcon} from 'assets/icons/servicesHeaderCartIcon.svg'
import {ReactComponent as ServicesPaginationRightArrow} from 'assets/icons/servicesPaginationRightArrow.svg'
import {ReactComponent as ServicesPaginationLeftArrow} from 'assets/icons/servicesPaginationLeftArrow.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
function Services() {
  const {t} =useTranslation()
  const [searchParams ,setSearchParams] = useSearchParams()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [activeCategory,setActiveCategory]=useState('all')
  const [services,setServices]=useState(localStorage.getItem('services')?JSON.parse(localStorage.getItem('services') as string) :[])
  const [items,setItems]= useState(localStorage.getItem('categoriesServices')?JSON.parse(localStorage.getItem('categoriesServices') as string) :[])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  const selector = useSelector((state:any)=>state?.GlobalReducer)
  const [cart ,setCart] = useState<any>({})
  useEffect(()=>{
    setCart(selector?.cart)
  },[selector])
  function getAllItems(){
    axiosConfig.get(`/services/all-categories-services`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then((res:any)=>{
      setItems(res?.data?.data)
      let itemsModified:any=[]
      res?.data?.data?.forEach((item:any)=>{
        itemsModified?.push(...item?.services)
      })
      setServices(itemsModified)
      localStorage.setItem('categoriesServices',JSON.stringify(res?.data?.data))
      localStorage.setItem('services',JSON.stringify(itemsModified))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  function handleActiveCategory(item:any,categoryId:any){
    setActiveCategory(categoryId)
    if(categoryId=='all'){
      let itemsModified:any=[]
      items?.forEach((item:any)=>{
        itemsModified?.push(...item?.services)
      })
      setServices(itemsModified)
    }else{
      setServices(item?.services)
    }
  }
  useEffect(()=>{
    getAllItems()
    if(searchParams.get('category')){
      handleActiveCategory([],(searchParams.get('category') as any))
    }
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      {/* <header className={styles['why-us__header']}>
        <h1 className={styles['why-us__header--title']}>{t('First Online Legal Translation Store')}</h1>
      </header> */}
      <section className={styles['services__sections']}>
        <Container>
          <div className={styles['services__sections-wrapper']}>
            <button 
              onClick={()=>handleActiveCategory([],'all')}
              className={`${styles['services__section-link']} ${activeCategory=='all'&&styles['services__section-link--active']}`}
            >
              {t('All')}
            </button>
            {
              items&&items?.map((item:any)=>(
                <button 
                  type='button'
                  onClick={()=>{handleActiveCategory(item,item?.category?.id)}}
                  className={`${styles['services__section-link']} ${activeCategory==item?.category?.id&&styles['services__section-link--active']}`}
                >
                  {item?.category?.title}
                </button>
              ))
            }
          </div>
          <div className={styles['services__sections-cart-wrapper']}>
            {/* <Link to='/' className={styles['services__sections-cart-link']}>
              <ServicesHeaderHeartIcon className={styles['services__sections-cart-link-icon']}/>
              <span className={styles['services__sections-cart-link-count']}>20</span>
            </Link> */}
            <Link to='/cart' className={styles['services__sections-cart-link']}>
              <ServicesHeaderCartIcon className={styles['services__sections-cart-link-icon']}/>
              <span className={styles['services__sections-cart-link-count']}>{cart?.products?.length||0}</span>
            </Link>
          </div>
        </Container>
      </section>

      <section className={styles['why-us']}>
        <Container>
          <Row>
            {/* <h2 className={styles['why-us__title']}>{t('Our Services')}</h2>
            {
              services&&services?.map((service:any)=>(
                <Col xl='3' lg='4' md='6' key={service?.id}>
                  <ServiceSmallCard service={service}/>
                </Col>
              ))
            } */}
            {
              services?.length!=0&&
              <h2 className={styles['why-us__title']}>{t('Our Services')}</h2>
            }
            {
              services?.length!=0?
                services && services?.map((service:any)=>(
                  <Col xl='3' lg='4' md='6' key={service?.id}>
                    <ServiceSmallCard service={service}/>
                  </Col>
                ))
              :<h1 className={styles['why-us__title']}>{t('There Is No Services')}</h1>
            }
          </Row>
            {/* <div className={styles['services__pagination-wrapper']}>
              <button className={styles['services__pagination-link']}>
                <ServicesPaginationLeftArrow/>
              </button>
              <button className={`${styles['services__pagination-link']} ${styles['services__pagination-link--active']}`}>1</button>
              <button className={`${styles['services__pagination-link']}`}>2</button>
              <button className={`${styles['services__pagination-link']}`}>3</button>
              <button className={`${styles['services__pagination-link']}`}>4</button>
              <button className={styles['services__pagination-link']}>
                <ServicesPaginationRightArrow/>
              </button>
            </div> */}
        </Container>
      </section>

        <Footer/>

    </motion.div>
    </>
  )
}

export default Services