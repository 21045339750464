import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import styles from './OrdersDetailsWrapper.module.css'
import { useCookies } from 'react-cookie'
function OrdersDetailsWrapper() {
  const {t} = useTranslation()
  const selector = useSelector(state=>state?.GlobalReducer)
  const params = useParams()
  const [order,setOrder] = useState({})
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getItem(){
    axiosConfig.get(`/orders/single-order/${params?.id}`,{
      headers:{
        authorization:`Bearer ${selector?.token}`,
        "accept-language": `${cookies?.i18next}`,
      }
    }).then(res=>{
      setOrder(res?.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getItem()
  },[])

  return (
    <section className={styles['orders-details-wrapper']}>
      <h2 className={styles['orders-details__sub-title']}>
        {t('Order')} <span className={styles['orders-details__sub-title--underline']}>#{order?.item_number}</span> {t('was placed on')}<span className={styles['orders-details__sub-title--underline']}>{order?.created_at}</span> {t('and is currently')} 
        <span className={styles['orders-details__sub-title--underline']}>
          {
              order?.status==1? t('Pending'):
              order?.status==2? t('Processing'):
              order?.status==3? t('Out Of Delivery'):
              order?.status==4? t('Completed'):
              order?.status==5? t('Cancelled'):
              ''
          }  
        </span>.
      </h2>
      <h1 className={styles['orders-details__title']}>
        {t("Order details")}
      </h1>
      <div className={`${styles['orders-details__content-wrapper']} ${styles['orders-details__content-wrapper--border']}`}>
        <label className={styles['orders-details__content-label']}>{t('SERVICE')}</label>
        <p className={styles['orders-details__content-description']}>{t("TOTAL")}</p>
      </div>
      {
        order?.services && order?.services.map(service =>(
          <>
          <div className={styles['orders-details__content-wrapper']} key={service?.service_id}>
            <div>
              <label className={styles['orders-details__content-label']}>
                {service?.type === 'serice' ? service?.service_title : service?.course_title} X <strong>{service?.quantity}</strong>
              </label>
              {service?.delivery_options&&<p className={styles['orders-details__content-label']}><strong>{t('Delivery Options')}</strong> :{service?.delivery_options}</p>}
              {service?.to_language&&<p className={styles['orders-details__content-label']}><strong>{t('To Language')}</strong> :{service?.to_language}</p>}
              {service?.file&&<a href={service?.file} target="_blank" className={styles['orders-details__content-label']}><strong>{t('File')}</strong> :{t('FIle Link')}</a>}
            </div>
            <p className={styles['orders-details__content-description']}>{service?.buying_price} {t('AED')}</p>
          </div>
            <hr/>
          </>
        ))
      }
      <div className={styles['orders-details__content-wrapper']}>
        <label className={styles['orders-details__content-label']}>{t('Subtotal')}:</label>
        <p className={styles['orders-details__content-description']}>{order?.sub_total_price} {t('AED')}</p>
      </div>
      <div className={styles['orders-details__content-wrapper']}>
        <label className={styles['orders-details__content-label']}>{t('Shipping Type')}:</label>
        <p className={styles['orders-details__content-description']}>
          {order?.shipping_type=='home_delivery'&&t('Home delivery')}
          {order?.shipping_type=='free_scan'&&t('Free Scan')}
          {order?.shipping_type=='free_delivery'&&t('Free Delivery')}
        </p>
      </div>
      {
        order?.shipping_type=='home_delivery' &&
        <div className={styles['orders-details__content-wrapper']}>
          <label className={styles['orders-details__content-label']}>{t('Shipping')}:</label>
          <p className={styles['orders-details__content-description']}>{order?.shipping_charges} {t('AED')}</p>
        </div>
      }
      <div className={styles['orders-details__content-wrapper']}>
        <label className={styles['orders-details__content-label']}>{t('Vat Value')}:</label>
        <p className={styles['orders-details__content-description']}>{order?.vat_value} {t('AED')}</p>
      </div>
      <div className={`${styles['orders-details__content-wrapper']} ${styles['orders-details__content-wrapper--margin']}`}>
        <label className={styles['orders-details__content-label']}>{t('Total')}:</label>
        <p className={styles['orders-details__content-description']}>{order?.total_price} {t('AED')}</p>
      </div>

      {
        order?.billing_address?.email!='undefined'&&
        <div className={`${styles['orders-details__content-wrapper']} ${styles['orders-details__content-wrapper--margin']}`}>
          <label className={styles['orders-details__content-label']}>{t('Email address')}:</label>
          <p className={styles['orders-details__content-description']}>
            {order?.billing_address?.email}
          </p>
        </div>
      }
      <hr/>
      <div className={styles['orders-details__content-details-wrapper']}>
        <div className={styles['orders-details__content-info-wrapper']}>
          <h3 className={styles['orders-details__content-info-title']}>{t('Billing address')}</h3>
          <ul className={styles['orders-details__content-info-list']}>
            {order?.billing_address?.name !='undefined' &&<li>{order?.billing_address?.name}</li>}
            {order?.billing_address?.company !='undefined' &&<li>{order?.billing_address?.company}</li>}
            {order?.billing_address?.house_number !='undefined' &&<li>{order?.billing_address?.house_number}</li>}
            {order?.billing_address?.apartment !='undefined' &&<li>{order?.billing_address?.apartment}</li>}
            {order?.billing_address?.city !='undefined' &&<li>{order?.billing_address?.city}</li>}
            {order?.billing_address?.street_address !='undefined' &&<li>{order?.billing_address?.street_address}</li>}
            {order?.billing_address?.email !='undefined' &&<li>{order?.billing_address?.email}</li>}
            {order?.billing_address?.phone !='undefined' &&<li>{order?.billing_address?.phone}</li>}
          </ul>
        </div>
      </div>
      <p className={styles['orders-details__content-email']}>{selector?.generalSettings?.project_email_address}</p>
    </section>
  )
}

export default OrdersDetailsWrapper