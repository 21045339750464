import React from 'react'
import { useTranslation } from 'react-i18next'
// import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './CoursesSuccessOrder.module.css'
import ShoppingCartNavBar from 'components/Global/Elements/ShoppingCartNavBar/ShoppingCartNavBar'
import successOrderIcon from 'assets/imgs/successOrderIcon.jpg'

function CoursesSuccessOrder() {
  const {t} =useTranslation()

  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        {/* <MetaTags>
              <title>{t('Cart -RD.Aroma')}</title>
        </MetaTags> */}
        <div className={styles['cart']}>
          <ShoppingCartNavBar isSuccess={true}/>
          <Container className={styles['cart__wrapper-container']}>
            <div className={styles['cart__content-wrapper']}>
              <img src={successOrderIcon} className={styles['cart__wrapper-img']} alt='success image'/>
              <h1 className={styles['cart__wrapper-title']}>{t('Payment Successful')}</h1>
              <p className={styles['cart__wrapper-description']}>{t('Your transaction has successfully been completed.')}</p>
            </div>
          </Container>
        </div>
    </motion.div>
    </>
  )
}

export default CoursesSuccessOrder