import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
// import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './ProductDetailsContent.module.css'
import BreadCrumb from 'components/Global/Elements/BreadCrumb/BreadCrumb'
import featuredProductItemImg from 'assets/imgs/FeaturedProductItem.jpg'
import productImage6 from 'assets/imgs/productImage6.jpg'
import productImage3 from 'assets/imgs/productImage3.jpg'
import productImage4 from 'assets/imgs/productImage4.jpg'
import {ReactComponent as RightArrowIcon} from 'assets/icons/rightArrow.svg'
import {ReactComponent as ClockIconn} from 'assets/icons/clockIconn.svg'
import {ReactComponent as CartIcon} from 'assets/icons/CartIcon.svg'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as MinusIcon} from 'assets/icons/minusIcon.svg'
import { useState } from 'react'
import { useEffect } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReactLoading from "react-loading";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { useCallback } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Link, useNavigate } from 'react-router-dom'
import AnotherProductNavigation from '../AnotherProductNavigation/AnotherProductNavigation'
import { axiosConfig } from 'utils/axiosConfig'
import { changeCartItems } from 'reduxStore/Global/GlobalActions'
import { useDispatch, useSelector } from 'react-redux'
import LoadingElement from 'components/Global/Elements/LoadingElement/LoadingElement'
import SuccessRequestPopup from 'components/Global/Elements/ItemAddedToCartSuccessPopUp/ItemAddedToCartSuccessPopUp'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ServiceCard from 'components/Global/Elements/Cards/ServiceCard/ServiceCard'
import ServiceSmallCard from 'components/Global/Elements/Cards/ServiceSmallCard/ServiceSmallCard'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'

function ProductDetailsContent({item,isLoaded}) {
  const {t} =useTranslation()
  const selectRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selector = useSelector(state=>state?.GlobalReducer)
  const [quantity,setQuantity] = useState(1)
  const [isloading,setIsloading] = useState(false)
  const [isAddedSuccessfully,setIsAddedSuccessfully] = useState(false)
  const [previousProduct,setPreviousProduct] = useState(null)
  const [file,setFile] = useState([])
  
  const [toLanguage,setToLanguage] = useState('')
  const [deliveryOptions,setDeliveryOptions] = useState('')
  const [images,setImages]=useState([productImage6])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);

  const sliderRef = useRef(null);

  function handleChangeQuantity(type){
    if(type=='prev'){
      quantity>1&&  setQuantity(prevValue=>prevValue-1)
    }else{
      setQuantity(prevValue=> prevValue+1)
    }
  }

  function addProductToCart(){
    setIsloading(true)
    // let addedData={
    //   service_id:item?.id,
    //   quantity:quantity,
    //   delivery_options:deliveryOptions,
    //   to_language:toLanguage,
    //   unique_identifier:localStorage.getItem('unique_identifier')
    // }
    const formData = new FormData()
    formData?.append('service_id',item?.id)
    formData?.append('quantity',quantity)
    formData?.append('delivery_options',deliveryOptions)
    formData?.append('to_language',toLanguage)
    formData?.append('unique_identifier',localStorage.getItem('unique_identifier'))
    formData?.append('file',file)

    axiosConfig.post('/cart/add-product-to-cart',formData,{
      headers:{
        authorization:`Bearer ${selector?.token}`,
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      setIsloading(false)
      // setIsAddedSuccessfully(true)
      toast.success(t('Service Added To Cart Successfully'))
      dispatch(changeCartItems(res?.data?.data))
      navigate('/cart')
    }).catch(err=>{
      toast.error(t('Something Went Wrong'))
      setIsloading(false)
    })
  }
  const [services,setServices]=useState(localStorage.getItem('services')?JSON.parse(localStorage.getItem('services') ) :[])
  function getAllItems(){
    axiosConfig.get(`/services/all-categories-services`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then((res)=>{
      let itemsModified=[]
      res?.data?.data?.forEach((item)=>{
        itemsModified?.push(...item?.services)
      })
      setServices(itemsModified)
      localStorage.setItem('services',JSON.stringify(itemsModified))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    getAllItems()
  },[])
  useEffect(()=>{
    if(item){
      setImages([item?.image])
    }
  },[item])
  
  return (
    <>
    <section className={styles['product-details']}>
      {/* {isLightBoxOpen && (
        <Lightbox
          mainSrc={images[lightBocPhotoIndex]}
          nextSrc={images[(lightBocPhotoIndex + 1) % images.length]}
          prevSrc={images[(lightBocPhotoIndex + images.length - 1) % images.length]}
          onCloseRequest={ ()=>{setIsLightBoxOpen(false)}}
          onMovePrevRequest={() => setLightBocPhotoIndex((lightBocPhotoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() =>setLightBocPhotoIndex((lightBocPhotoIndex + 1) % images.length) }
        />
      )} */}
      <Container>
        {/* <BreadCrumb text={t('THE MYSTIQUE')}/> */}
        {/* <AnotherProductNavigation previousProduct={previousProduct} nextProduct={nextProduct}/> */}
        {/* {isAddedSuccessfully && 
          <SuccessRequestPopup 
            product={item?.product} 
            selector={selector} 
            setIsAddedSuccessfully={setIsAddedSuccessfully}
            quantity={quantity}
            selectedSizeId={productSize}
            sendReceipt={sendReciept}
            greetingCard={greetingCard}
            greetingCard_message={greetingCard}
            productPrice={productPrice}
          />
        } */}
        {
          isLoaded?
            <Row>
              <Col md='4'>
                  <div className={styles['product-details__main-img-wrapper']}>
                    <img 
                      src={item?.image} 
                      alt='product image' 
                      className={`${styles['product-details__main-img']}`}
                    />
                  </div>
              </Col>
              <Col md='8' className='px-2 px-md-5'>
                <div className={styles['product-details__content']}>
                  <h1 className={styles['product-details__title']}>{item?.title}</h1>
                  <h1 className={styles['product-details__sub-title']}>{item?.description}</h1>
                  <p className={styles['product-details__price']}>{t('AED')} {item?.price}</p>

                  {/* <div className={styles['product-details__size-wrapper']}>
                    <label className={styles['product-details__size-label']}>{t('Document Type')}</label>
                    <div className={styles['product-details__size-input-wrapper']}>
                      <select 
                      className={styles['product-details__size-input']} 
                      >
                        <option value={''} >{t('Choose an option')}</option>
                        <option value={'pdf'} >{t('PDF')}</option>
                        <option value={'email'} >{t('Email')}</option>
                        <option value={'delivery'} >{t('Delivery')}</option>
                      </select>
                    </div>
                  </div> */}
                  <div className={styles['product-details__size-wrapper']}>
                    <label className={styles['product-details__size-label']}>{t('To Language')}</label>
                    <div className={styles['product-details__size-input-wrapper']}>
                      <select 
                      className={styles['product-details__size-input']} 
                      // ref={selectRef} 
                      onChange={(e)=>setToLanguage(e.target.value)}
                      value={toLanguage}
                      >
                        <option value={''} >{t('Choose an option')}</option>
                        <option value={'arabic'} >{t('Arabic')}</option>
                        <option value={'english'} >{t('English')}</option>
                        <option value={'germany'} >{t('Germany')}</option>
                        <option value={'french'} >{t('French')}</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles['product-details__size-wrapper']}>
                    <label className={styles['product-details__size-label']}>{t('Delivery Options')}</label>
                    <div className={styles['product-details__size-input-wrapper']}>
                      <select 
                      className={styles['product-details__size-input']} 
                      // ref={selectRef} 
                      onChange={(e)=>setDeliveryOptions(e.target.value)}
                      value={deliveryOptions}
                      >
                        <option value={''} >{t('Choose an option')}</option>
                        <option value={'pdf'} >{t('PDF')}</option>
                        <option value={'email'} >{t('Email')}</option>
                        <option value={'delivery'} >{t('Delivery')}</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles['product-details__size-wrapper']}>
                    <label className={styles['product-details__size-label']}>{t('File')}</label>
                    <div className={styles['product-details__size-input-wrapper']}>
                      <input
                        type='file' 
                        className={styles['product-details__size-input']} 
                        onChange={(e)=>setFile(e.target.files?.[0])}
                      />
                    </div>
                  </div>
                </div>
                
                <div className={styles['product-details__cart-wrapper']}>
                  <div className={styles['product-details__quantity-wrapper']}>
                    <button className={styles['product-details__quantity-button']} type='button' onClick={()=>{handleChangeQuantity('prev')}}>
                      {/* <MinusIcon className={styles['product-details__quantity-icon']}/> */}-
                    </button>
                    <p className={styles['product-details__quantity-text']}>{quantity}</p>
                    <button className={styles['product-details__quantity-button']}  type='button' onClick={()=>{handleChangeQuantity('next')}}>
                      {/* <PlusIcon className={styles['product-details__quantity-icon']}/> */}+
                    </button>
                  </div>
                  <button 
                    type='button' 
                    className={styles['product-details__cart-button']} 
                    onClick={addProductToCart} 
                    disabled={isloading}
                  >
                    {
                      isloading?
                      <ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                      :
                      <>
                        <CartIcon className={styles['product-details__cart-icon']}/>
                        {t("Add To Cart")}
                      </>
                    }
                  </button> 
                  
                </div>
              </Col>
                <div className={styles['product-details__additional-info']}>
                  <ul className={styles['product-details__additional-info-list']}>
                    <li>
                      <button type='button' className={`${styles['product-details__additional-info-button']} ${styles['product-details__additional-info-button--active']}`}>{t('Description')}</button>
                    </li>
                    {/* <li>
                      <button type='button' className={`${styles['product-details__additional-info-button']}`}>{t('Additional Information')}</button>
                    </li>
                    <li>
                      <button type='button' className={`${styles['product-details__additional-info-button']}`}>{t('Reviews (0)')}</button>
                    </li> */}
                  </ul>
                  <div className={styles['product-details__additional-info-content-wrapper']}>
                    <p className={styles['blog__description']} dangerouslySetInnerHTML={{__html: item?.full_description}}/>
                    {/* <h2 className={styles['product-details__additional-info-title']}>{t('Delivery Options:')}</h2>
                    <h3 className={styles['product-details__additional-info-title']}>{t('Non-urgent Delivery:')}</h3>
                    <p className={styles['product-details__additional-info-desc']}><ClockIconn/>{t('2-4 working days*.')}</p>
                    <h3 className={styles['product-details__additional-info-title']}>{t('Urgent Delivery:')}</h3>
                    <p className={styles['product-details__additional-info-desc']}><ClockIconn/>{t('2-4 working days*.')}</p>
                    <h3 className={styles['product-details__additional-info-title']}>{t('Top Urgent Delivery:')}</h3>
                    <p className={styles['product-details__additional-info-desc']}><ClockIconn/>{t('2-4 working days*.')}</p>
                    <p className={styles['product-details__additional-desc']}>
                      {t('* Important Note: Please note that this attestation service largely depends on the government entity’s procedures and regulations, which might be changing without prior notice by the government entity. Therefore, any unforeseen delay due to this will exempt our company from any responsibility because it is beyond our control.')}
                    </p>
                    <h3 className={styles['product-details__additional-title']}>
                      {t('Why do you need the MOFA attestation service?')}
                    </h3>
                    <p className={styles['product-details__additional-desc']}>
                      {t('The MOFA attestation service is required for documents issued abroad and intended to be used for official purposes to be attested. Contact our team to assist you with attesting, notarizing and certifying your documents.The UAE Ministry of Foreign Affairs and International Cooperation provides attestation services for all kinds of documents. The purpose of this procedure is to check the validity of stamp and signature on such documents, whether issued inside or outside Country.This encompasses educational and medical certificates, marriage and divorces contracts, powers of attorney, etc., in addition to the attestation service of commercial contracts and agreements.Service Procedures:Select the Service:Contact us online by selecting the service or visit any of our branches.Pay the Fees:You can pay online through our online store, bank transfer, credit card or cash Submit the original document:You can drop it at any of our branches, send it to us by courier or select our delivery service (charges apply) to collect the file from you.Receive the attested original document:You can collect the files from our offices, by courier or through our delivery service (charges apply)')}
                    </p>
                    <h4 className={styles['product-details__additional-sub-title']}>
                      {t('Required Documents')}
                    </h4>
                    <p className={styles['product-details__additional-desc']}>
                      {t('Original Document')}
                    </p> */}
                  </div> 
                </div>
                <div className={styles['product-details__additional-products']}>
                  <h3 className={styles['product-details__additional-title']}>
                    {t('You Also May Like....')}
                  </h3>
                  <div className={styles['product-details__additional-products-wrapper']}>
                    <Row>
                      {/* <Col xl='3' lg='4' md='6'>
                        <ServiceSmallCard/>
                      </Col> */}
                      {
                        services&&services?.map((service)=>(
                          <Col xl='3' lg='4' md='6' key={service?.id}>
                            <ServiceSmallCard service={service}/>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>
                </div>
                {/* <div className={styles['product-details__additional-products']}>
                  <h3 className={styles['product-details__additional-title']}>
                    {t('Recently Viewed Products')}
                  </h3>
                  <div className={styles['product-details__additional-products-wrapper']}>
                    <Row>
                      <Col xl='3' lg='4' md='6'>
                        <ServiceSmallCard/>
                      </Col>
                    </Row>
                  </div>
                </div> */}
            </Row>

            :<>s</>
            // <Row>
            //   <Col md='4'>
            //     <div className={styles['product-details__main-img-wrapper']}>
            //       <Skeleton className={styles['product-details__main-img-wrapper']}/>
            //     </div>
            //     <div className={styles['product-details__preview-imgs-wrapper--skeleton']}>
            //       <Skeleton className={styles['product-details__preview-img--skeleton']}/>
            //       <Skeleton className={styles['product-details__preview-img--skeleton']}/>
            //       <Skeleton className={styles['product-details__preview-img--skeleton']}/>
            //       <Skeleton className={styles['product-details__preview-img--skeleton']}/>

            //     </div>
            //   </Col>
            //   <Col md='8'>
            //     <div className={styles['product-details__content']}>
            //       <h1 className={styles['product-details__title']}><Skeleton /></h1>
            //       <p className={styles['product-details__price']}><Skeleton /></p>

            //         <div className={styles['product-details__size-wrapper']}>
            //           <Skeleton />
            //         </div>

            //         <div className='mb-4'>
            //           <Skeleton count={3}/>
            //         </div>
            //     </div>
            //     {
            //       item?.product?.description&&
            //       <Skeleton  count={4}/>
            //     }
            //     <div >
            //       <Skeleton />
            //     </div>
            //   </Col>
            // </Row>
        }

      </Container>
    </section>
    </>
  )
}

export default ProductDetailsContent