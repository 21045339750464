import styles from './ServiceCard.module.css'
import { useTranslation } from 'react-i18next'
import ourServicesImg from 'assets/imgs/ourServicesImg.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useRef } from 'react'
import ReactLoading from "react-loading";
// type ComponentCustomPropsTypes={
//     blog:BlogType
// }
// function BlogCard(props:ComponentCustomPropsTypes) {
function ServiceCard(props:any) {
    const {item}= props
    const {t} =useTranslation()
    const imgRef= useRef()
    
    return (
        <div className={styles['service-card']}>
            <div className={styles['service-card__wrapper']}>
                <div className={`loading__item`} ref={imgRef as any}>
                    <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                </div>
                <img 
                    src={item?.image} 
                    className={styles['service-card__image']} 
                    alt='section Padimagege'
                    onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                />
                {/* <img src={item?.image} className={styles['service-card__image']} alt='service image'/> */}
                <div className={styles['service-card__content-wrapper']}>
                    <h2 className={styles['service-card__content-title']}>{item?.title}</h2>
                    <Link to={`/services?category=${item?.id}`} className={styles['service-card__link']}>{t('Services')}</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard