import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './OurServices.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import whyUsImg from 'assets/imgs/whyUsImg.jpg'
import whoWeAreWhatWeOfferImg from 'assets/imgs/whoWeAreWhatWeOfferImg.jpg'
import WhoWeAreTranslationFirm from 'assets/imgs/WhoWeAreTranslationFirm.png'
import Footer from 'components/Global/Layout/Footer/Footer'
import ServiceCard from 'components/Global/Elements/Cards/ServiceCard/ServiceCard'
import { axiosConfig } from 'utils/axiosConfig'
import { useCookies } from 'react-cookie'
import ReactLoading from "react-loading";
import OurServicesBG from 'assets/imgs/OurServicesBG.jpg'
function OurServices() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [items,setItems]= useState(localStorage.getItem('categories')?JSON.parse(localStorage.getItem('categories') as string) :[])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getAllItems(){
    axiosConfig.get(`/category/all-categories`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then((res:any)=>{
      setItems(res?.data?.data)
      localStorage.setItem('categories',JSON.stringify(res?.data?.data))
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }
  useEffect(()=>{
    getAllItems()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <header className={styles['why-us__header']}>
        {/* <div className='position-relative'> */}
        <div className={`loading__item`} id={`loading__item-why-us-header`}>
          <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
        </div>
        <img 
          src={OurServicesBG} 
          className={styles['why-us__img']} 
          alt='Why Us Padge'
          onLoad={(e)=>(document.getElementById(`loading__item-why-us-header`) as any).classList.add('d-none')}
          />
        {/* </div> */}
        <h1 className={styles['why-us__header--title']}>{t('First Online Legal Translation Store')}</h1>
      </header>

      <section className={styles['why-us']}>
        <Container>
          <Row>
            {
              items?.length!=0&&
              <h2 className={styles['why-us__title']}>{t('Our Services')}</h2>
            }
            {
              items?.length!=0?
                items && items?.map((item:any)=>(
                <Col xl='4' lg='6' key={item?.id}>
                  <ServiceCard item={item}/>
                </Col>
                ))
              :<h1 className={styles['why-us__title']}>{t('There Is No Services')}</h1>
            }
            {/* // {
            //   items && items?.map((item:any)=>(
            //     <Col xl='4' lg='6' key={item?.id}>
            //       <ServiceCard item={item}/>
            //     </Col>
            //   ))
            // } */}
          </Row>
        </Container>
      </section>

        <Footer/>

    </motion.div>
    </>
  )
}

export default OurServices