import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useCallback, useRef, useState ,useEffect} from 'react'
import styles from './Header.module.css'
import { Swiper, SwiperSlide ,SwiperRef } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import SwiperHeader from 'components/Global/Elements/SwiperHeader/SwiperHeader';
import {ReactComponent as LeftArrowTall} from 'assets/icons/leftArrowTall.svg'
import {ReactComponent as RightArrowTall} from 'assets/icons/rightArrowTall.svg'
import StyledButton from './HeaderStyled';
import { SliderType } from 'views/Home/Home';
// type SliderType={
//   title:string,
//   subTitle:string,
//   videoSrc:string,
//   bgSrc:string
// }
type ComponentPropsType ={
  sliders:SliderType[],
  // settings:any
}
function Headere(props:ComponentPropsType) {
  const {sliders} = props
  const {t} =useTranslation()
  const sliderRef = useRef<SwiperRef>(null)
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [activeSlideIndex,setActiveSlideIndex]=useState(0)
  const [slides,setSlides]=useState<SliderType[]>([])
  // title ,subTitle,videoSrc,bgSrc,sliderNumber
  // const [images,setImages]=useState<any>([])
  
  const handlePrev = useCallback(() => {
  if (!sliderRef.current) return;
    sliderRef?.current?.swiper?.slidePrev();
  //   setActiveSlideIndex(prevVal=>prevVal-1)
  // if(sliderRef?.current?.swiper?.isBeginning) {
  //     setActiveSlideIndex(0)
  //   }
}, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
      // setActiveSlideIndex(prevVal=>{
      //   console.log('prevVal',prevVal)
      //   return prevVal+1
      // })
      sliderRef.current.swiper.slideNext();
    // if(sliderRef.current.swiper.isEnd) {
    //   setActiveSlideIndex(slides?.length-1)
    // }
  }, []);

  function changeActiveSlideIndex(index:any){
    setActiveSlideIndex(index)
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(index);;
  }

  function changeActiveSlider(e:any){
    setActiveSlideIndex(sliderRef?.current?.swiper?.activeIndex as number)
  }

  useEffect(()=>{
    setSlides(sliders)
  },[sliders])
  return (
    <>
      <section className={styles['header']}>
        {/* <SectionTitle title={`${t('Featured Work')}`}/> */}
        {/* <Container className='h-100'> */}
          {/* <div className={styles['header__video-wrapper']}> */}
          <Swiper
              // onSwiper={changeActiveSlider}
              onSlideChange={(e) => changeActiveSlider(e.realIndex)}
              spaceBetween={0}
              slidesPerView={1}
              ref={sliderRef}
              // cssMode= {true}
              // ref={swiperRef}
              // loop={true}
              autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
              }}
              // breakpoints={{
              //     768: {
              //         slidesPerView: 1.7,
              //         spaceBetween: 15,
              //     },
              // }}
              pagination={{
                  clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper h-100"
            >
              {
                slides && slides?.map((slide:any,index:number)=>(
                  <SwiperSlide key={slide?.id}>
                    <SwiperHeader
                      title={slide?.title} 
                      description={slide?.description} 
                      image={slide?.image}
                      service_id={slide?.service_id}
                      />
                  </SwiperSlide>
                ))
              }
            </Swiper>
            <div className={styles['header__slider-controls-wrapper']}>
              <button type='button' className={styles['header__slider-control-wrapper--arrow-button']} onClick={handlePrev}>
                <LeftArrowTall className={styles['header__slider-control-arrow']}/>
              </button>
              
              {
                slides && slides?.map((slide:SliderType,index:number)=>(
                  <button type='button' onClick={()=>{changeActiveSlideIndex(index)}}
                  className={`${styles['header__slider-control-wrapper']} 
                  ${activeSlideIndex==index?styles['header__slider-control-wrapper--active']:''}`}></button>
                ))
              }
              {/* <button type='button' onClick={()=>{changeActiveSlideIndex(0)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==0?styles['header__slider-control-wrapper--active']:''}`}>01</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(1)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==1?styles['header__slider-control-wrapper--active']:''}`}>02</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(2)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==2?styles['header__slider-control-wrapper--active']:''}`}>03</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(3)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==3?styles['header__slider-control-wrapper--active']:''}`}>04</button> */}

              <button type='button' className={styles['header__slider-control-wrapper--arrow-button']} onClick={handleNext}>
                <RightArrowTall className={styles['header__slider-control-arrow']}/>
              </button>
            </div>
          {/* </div> */}
        {/* </Container> */}
      </section>
    </>
  )
}

export default Headere