import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import styles from './CourseDetails.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import { axiosConfig } from 'utils/axiosConfig'
import { BlogType } from 'components/Home/HomeBlogs/HomeBlogs'
import { useCookies } from 'react-cookie'
import CourseImage from 'assets/imgs/CourseImage.jpg'
import {ReactComponent as CoursesClockIcon} from 'assets/icons/CoursesClockIcon.svg'
import {ReactComponent as PreviewCourseVideoIcon} from 'assets/icons/previewCourseVideoIcon.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CoursesCard from 'components/Global/Elements/Cards/CoursesCard/CoursesCard'
// import {ReactComponent as CourseDetailsTrueIcon} from 'assets/icons/courseDetailsTrueIcon.svg'
import courseDetailsTrueIcon from 'assets/icons/courseDetailsTrueIcon.svg'
import ReactLoading from "react-loading";
import VideoModal from 'components/Global/Elements/VideoModal/VideoModal'
import { useDispatch } from 'react-redux'
import { changeCartItems } from 'reduxStore/Global/GlobalActions'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
function CourseDetails() {
  const {t} =useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selector = useSelector((state:any)=>state?.GlobalReducer)
  const imgRef= useRef()
  const imgPrevRef= useRef()
  const [isloading,setIsloading]=useState(false)
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [course,setCourse]= useState<any>({})
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getCourse(){
    axiosConfig.get(`/courses/single-course/${params?.id}`,{ 
      headers: {
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      // setIsLoading(false)
      setCourse(res.data?.data)
    }).catch(err=>{
        // setIsLoading(false)
        console.log(err)
    })
  }

  function addProductToCart(){
    setIsloading(true)
    let addedData={
      course_id:course?.id,
      unique_identifier:localStorage.getItem('unique_identifier')
    }
    axiosConfig.post('/cart/add-product-to-cart',addedData,{
      headers:{
        authorization:`Bearer ${selector?.token}`,
        "Accept-Language": `${cookies?.i18next||'en'}`
      }
    }).then(res=>{
      setIsloading(false)
      // setIsAddedSuccessfully(true)
      toast.success(t('Course Added To Cart Successfully'))
      dispatch(changeCartItems(res?.data?.data))
      navigate('/cart')
    }).catch(err=>{
      console.log(err)
      toast.error(t('Something Went Wrong'))
      setIsloading(false)
    })
  }


  useEffect(()=>{
    getCourse()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['course-details']}>
          <Container>
            <div className={styles['course-details__course-preview']}>
              <div className={styles['course-details__course-preview-img-wrapper']}>

                  <div className='position-relative'>
                    <div className={`loading__item`} ref={imgPrevRef as any}>
                        <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                    </div>
                    <img 
                        src={course?.image} 
                        className={styles['course-details__course-preview-img']} 
                        alt='Course image'
                        onLoad={(e)=>(imgPrevRef?.current as any)?.classList?.add('d-none')}
                    />
                  </div>
                  {/* <img src={course?.image} className={styles['course-details__course-preview-img']}/> */}
                  <div className={styles['course-details__course-preview-video-button']}>

                    <VideoModal video={course?.course_link}>
                      <PreviewCourseVideoIcon/>
                    </VideoModal>
                  </div>
                  {/* <button type='button' className={styles['course-details__course-preview-video-button']}>
                    <PreviewCourseVideoIcon/>
                  </button> */}
              </div>
              <div className={styles['course-details__course-preview-content-wrapper']}>
                <div className={styles['course-details__course-preview-video-price-wrapper']}>
                  <p className={styles['course-details__course-preview-price-after-discount']}>{t('AED')}{course?.price_after_discount}</p>
                  {
                    course?.discount&&
                    <p className={styles['course-details__course-preview-price-before-discount']}>{t('AED')}{course?.price_before_discount}</p>
                  }
                </div>
                <button 
                  type='button' 
                  className={styles['course-details__course-preview-add-to-cart']} 
                  onClick={addProductToCart} 
                  disabled={isloading}
                >
                  {
                    isloading?
                    <ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                    :
                    <>
                      {t("Add To Cart")}
                    </>
                  }
                </button> 
                {/* <Link to='/courses/courses-check-out' className={styles['course-details__course-preview-buy-now']}>{t('Buy Now')}</Link> */}
              </div>
            </div>
            <div className={styles['course-details__img-wrapper']}>
              {/* <div className='position-relative'> */}
                <div className={`loading__item`} ref={imgRef as any}>
                    <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                </div>
                <img 
                    src={course?.image} 
                    className={styles['course-details__img']} 
                    alt='Course image'
                    onLoad={(e)=>(imgRef?.current as any)?.classList?.add('d-none')}
                />
              {/* </div> */}
              {/* <img src={course?.image} className={styles['course-details__img']} alt='course image'/> */}
                <div className={styles['course-details__content-wrapper']}>
                    <h2 className={styles['course-details__content-title']}>{course?.title}</h2>
                    <div className={styles['course-details__info-wrapper']}>
                        <p className={styles['course-details__info']} >
                            <CoursesClockIcon/>{course?.total_time} {t('Hours')}
                        </p>
                        <p className={styles['course-details__info']} >
                            {course?.total_lectures} {t('Lectures')}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles['course-details__description-wrapper']}>
              <h2 className={styles['course-details__description-title']}>{t("What you'll learn")}</h2>
              <div className={styles['course-details__features-wrapper']}>
                {
                  course?.features &&course?.features?.map((feature:any,index:number)=>(
                    <div className={styles['course-details__feature-wrapper']} key={index}>
                      <img src={courseDetailsTrueIcon} className={styles['course-details__feature-img']} alt='true icon'/>
                      <p className={styles['course-details__feature']}>{feature?.title}</p>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={styles['course-details__course-contents-wrapper']}>
              {
                course?.contents &&course?.contents?.map((content:any,index:number)=>(
                  <div className={styles['course-details__course-content-wrapper']}>
                    <h3 className={styles['course-details__course-content-title']}>{content?.title}</h3>
                    <p className={styles['course-details__course-content-time']}>{content?.time}{t('min')} {content?.lectures} {t('Lectures')}</p>
                  </div>
                ))
              }
            </div>
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default CourseDetails