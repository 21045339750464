import React from 'react'
import { useTranslation } from 'react-i18next'
// import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import AuthHeader from 'components/Global/Elements/AuthHeader/AuthHeader'
import AccountWrapper from 'components/Account/AccountWrapper/AccountWrapper'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import Footer from 'components/Global/Layout/Footer/Footer'
import styles from './Account.module.css'
function Account() {
  const {t} =useTranslation()
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        {/* <MetaTags>
              <title>{t('Account -RD.Aroma')}</title>
        </MetaTags> */}
        {/* <NavBar/> */}
        <main className={styles['main']}>
          <AuthHeader/>       
          <AccountWrapper/>
        </main>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Account